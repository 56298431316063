@keyframes bounce {

  to,
  from,
  10%,
  100% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(-10px);
  }

  25% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(-15px);
  }

  45% {
    transform: translateY(0);
  }
}

@keyframes nod {
  60% {
    transform: rotate(0);
  }

  70% {
    transform: rotate(10deg);
  }

  80% {
    transform: rotate(0);
  }

  90% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes nod-2 {
  45% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(0);
  }

  70% {
    transform: rotate(-10deg);
  }

  80% {
    transform: rotate(0);
  }
}

@keyframes scale {

  to,
  from,
  10%,
  100% {
    transform: scale3d(1, 1, 1);
  }

  40% {
    transform: scale3d(1, 1, 1);
  }

  45% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  50% {
    transform: scale3d(1, 1, 1);
  }

  55% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  75% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scale-2 {

  to,
  from,
  0%,
  100%,
  25% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.1);
  }

  40% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(1);
  }
}