.workflow-method {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 64px;
  padding: 80px 25px 120px;
  background: $tint-blue;

  &__title {
    margin-bottom: 64px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  &__read {
    max-width: 768px;
    width: 100%;
  }
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    border-radius: 8px;
    background: #fff;
    @include min-M {
      flex-direction: row;
      padding: 56px 0;
      max-width: 1050px;
    }
  }
  &__box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding-right: 0;
    padding-left: 0;
    @include min-M {
      padding-right: 56px;
      padding-left: 48px;
      width: 100%;
    }
    &--border {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 2px solid $tint-blue;
      @include min-M {
        margin-bottom: 0;
        padding-right: 48px;
        padding-bottom: 0;
        padding-left: 56px;
        border-right: 2px solid $tint-blue;
        border-bottom: none;
      }
    }
  }
  &__sub-title {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  &__img {
    max-width: 377px;
    width: 100%;
    height: auto;
  }
  &__text {
    padding: 0;
    font-size: 14px;
    @include min-M {
      padding: 0 8px;
    }
  }
  &__area {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    width: 100%;
    @include min-M {
      padding: 0 8px;
    }
  }
  &__gray-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    padding: 13px;
    width: 100%;
    border-radius: 4px;
    background: $tint-blue;
    @include min-M {
      padding: 16px;
    }
  }
  &__group {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  &__icon-img {
    width: 20px;
    height: 20px;
  }
  &__group-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
  }
  &__list {
    padding-left: 20px;
    list-style: disc;
  }
  &__list-item {
    font-size: 14px;
  }
}
