.contact-button-area {
  padding-top: 120px;
  padding-bottom: 160px;
  text-align: center;

  @include min-M {
    padding-top: 70px;
    padding-bottom: 150px;
  }

  //複数ボタン
  &--multiple {
    @include min-M {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    display: block;
    margin: 0 auto;

    @include min-M {
      margin: 0;
      width: 46%;
      max-width: 300px;
    }

    &+& {
      margin-top: 20px;

      @include min-M {
        margin-top: 0;
      }
    }
  }
}