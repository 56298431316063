.case-img{
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: rgba($dark-black,.2);
  
  @include min-S{
    height: 582px;
    max-height: 582px;
    margin-bottom: 65px;
    aspect-ratio: inherit;
    //background-size: 1782px;
  }
  &--03{
    background-image: url("/assets/img/case/bottom-img-003.png");
    @include min-M{
      background-image: url("/assets/img/case/bottom-img-003--pc.png");
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/assets/img/case/bottom-img-003--pc@2x.png");
    }
  }
  &--04{
    background-image: url("/assets/img/case/bottom-img-004.png");
    @include min-M{
      background-image: url("/assets/img/case/bottom-img-004--pc.png");
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/assets/img/case/bottom-img-004--pc@2x.png");
    }
  }
}