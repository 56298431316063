.case-voice {
  position: relative;
  padding-top: 100px;
  padding-bottom: 116px;

  @include min-M {
    //margin-top: 65px;
    margin-bottom: 45px;
    padding-top: 20px;
    padding-bottom: 70px;
  }

  &:before,
  &:after {
    font-family: $font-yu;
    position: absolute;
    color: $color-comma;
    font-size: 130px;
    line-height: 1;
    opacity: .5;
  }

  &:before {
    top: 36px;
    left: -7px;
    content: "“";

    @include min-M {
      top: 0;
      left: -70px;
    }
  }

  &:after {
    right: -9px;
    bottom: -32px;
    content: "”";

    @include min-M {
      right: -70px;
      bottom: -60px;
    }
  }

  &__heading {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 2rem;
  }

  &__text {
    font-size: 15px;
    line-height: 1.8;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}