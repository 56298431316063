.history {
  position: relative;
  padding-top: 100px;
  background-image: url("/assets/img/company/bg-history.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include min-M {
    padding-top: 115px;
    background-image: url("/assets/img/company/bg-history--pc.jpg");
  }

  &__heading {
    @include absolute($left: 0, $right: 0);
    z-index: 1;
  }

  &__main {
    position: relative;
    padding-top: 110px;
    padding-bottom: 20px;
    opacity: 0;
    @include min-M {
      padding-top: 110px;
      height: 385px;
    }
  }

  &__line {
    padding-bottom: 50px;
    width: 2370px;
    border-color: rgba(#fff, .2);
  }

  &__text {
    margin-top: 8px;
    width: 165px;
    letter-spacing: initial;
    font-size: 1.2rem;
    line-height: 1.2;
  }

  &__img {
    width: 60px;
    height: 23px;
    pointer-events: none;

    &--thunder {
      @include absolute($left: -25px, $right: 0, $bottom: 70px);
      width: 104px;
    }

    &--rain {
      @include absolute($left: -7px, $bottom: -28px);
      width: 114px;
    }

    &--sun {
      @include absolute($top: -45px, $right: 70px);
      width: 35px;
    }

    &--graph {
      @include absolute($bottom: 0);
      width: 2348px;
      height: 100%;
      pointer-events: none;
    }
  }

  &__year {
    position: absolute;
    pointer-events: none;

    &--2006 {
      top: 233px;
      left: 25px;
    }

    &--2007 {
      top: 215px;
      left: 165px;
    }

    &--2008 {
      top: 173px;
      left: 295px;
    }

    &--2009 {
      top: 238px;
      left: 410px;
    }

    &--2010 {
      top: 185px;
      left: 580px;
    }

    &--2011 {
      top: 230px;
      left: 730px;
    }

    &--2012 {
      top: 245px;
      left: 900px;
    }

    &--2013 {
      top: 211px;
      left: 1050px;
    }

    &--2014 {
      top: 175px;
      left: 1200px;
    }

    &--2015 {
      top: 145px;
      left: 1320px;
    }

    &--2016 {
      top: 122px;
      left: 1450px;
    }

    &--2017 {
      top: 100px;
      left: 1600px;
    }

    &--2018 {
      top: 87px;
      left: 1780px;
    }

    &--2019 {
      top: 45px;
      left: 1925px;
    }

    &--2020 {
      top: 55px;
      left: 2050px;
    }

    &--2021 {
      top: 43px;
      left: 2200px;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
    height: 50px;
    @include min-M {
      padding-right: 50px;
      padding-bottom: 10px;
      padding-left: 50px;
    }
  }

  &__bottom-text {
    color: rgba(#fff, .6);
    letter-spacing: .1em;
    font-weight: 600;
    font-size: 2.1rem;
    font-family: $font-josefin;
    @include min-M {
      font-size: 2.3rem;
    }
  }

  &__arrow-wrap {
    @include min-M {
      margin-bottom: -10px;
    }
  }

  &__arrow {
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }
  }
}
