.salesforce-firstview {
  padding: 80px 0 0;
  width: 100%;
  background-image: url("/assets/img/service/salesforce/top-bg.png");
  background-size: 62%;
  background-repeat: no-repeat;
  @include min-M {
    background-size: 53%;
  }

  &__inner {
    margin: auto;
    max-width: 836px;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 2.6rem;
    @include min-M {
      margin-top: 80px;
      font-size: 36px;
      line-height: 1;
    }
  }

  &__text {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.9;
    @include min-M {
      margin-top: 33px;
      font-size: 16px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    margin-top: 55px;
    @include min-M {
      row-gap: 93px;
      margin-top: 82px;
    }
  }

  &__item {
    width: 100%;
    text-align: center;
    @include min-M {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }
  }

  &__content {
    margin: auto;
    max-width: 100%;
    text-align: center;
    @include min-S {
      width: 80%;
    }
    @include min-M {
      margin: 0;
      max-width: 410px;
      text-align: left;
    }
  }

  &__content-title {
    position: relative;
    display: inline-block;
    padding-left: 40px;
    color: $light-navy;
    font-weight: 700;
    font-size: 18px;
    @include min-M {
      padding-left: 50px;
      font-size: 26px;
    }

    &--01 {
      &::before {
        content: "01";
      }
    }

    &--02 {
      &::before {
        content: "02";
      }
    }

    &::before {
      position: absolute;
      top: -12px;
      left: 0;
      color: $light-blue-num;
      letter-spacing: .1em;
      font-weight: 700;
      font-size: 20px;
      font-family: Josefin Slab;
    }

    &::after {
      position: absolute;
      top: -4px;
      left: 6px;
      content: url("/assets/img/service/salesforce/line.svg");
      @include min-M {
        left: 9px;
      }
    }
  }

  &__content-text {
    margin-top: 20px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    @include min-M {
      margin-top: 14px;
      font-size: 15px;
    }
  }

  &__img {
    margin-top: 20px;
    max-width: 348px;
    width: 100%;
    @include min-M {
      margin-top: 0;
    }
  }
}
