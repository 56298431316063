.about-flow {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 25px 120px;
  @include min-M {
    padding: 120px 25px;
  }

  &__title {
    margin-bottom: 48px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  &__text {
    margin-bottom: 80px;
    max-width: 768px;
  }
  &__img {
    margin-bottom: 40px;
    max-width: 150px;
    width: 100%;
    @include min-M {
      margin-bottom: 80px;
      max-width: 1050px;
    }
  }
  &__link-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 2.5;

    text-decoration-line: underline;
  }
}
