/**
 * Utility Margin
 */
// margin-top:0px ~ margin-top:50px
@for $i from 0 through 50 {
  .mt#{$i * 2} {
    margin-top: #{$i * 2}px;
  }
}

// margin-bottom:0px ~ margin-bottom:50px
@for $i from 0 through 50 {
  .mb#{$i * 2} {
    margin-bottom: #{$i * 2}px;
  }
}

// margin-left:0px ~ margin-left:50px
@for $i from 0 through 50 {
  .ml#{$i * 2} {
    margin-left: #{$i * 2}px;
  }
}

// margin-right:0px ~ margin-right:50px
@for $i from 0 through 50 {
  .mr#{$i * 2} {
    margin-right: #{$i * 2}px;
  }
}
.pb150 {
  padding-bottom: 150px;
}
.m0a {
  margin: 0 auto;
}