.fade-in {
  opacity: 0;

  &.is-scrollIn {
    opacity: 1;
    transition: opacity .5s ease-in-out;
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(20px);

  &.is-scrollIn {
    animation: fadeUp .5s ease-in-out forwards;

    &.fade-up-2 {
      animation: fadeUp .5s ease-in-out forwards .1s;
    }

    &.fade-up-3 {
      animation: fadeUp .5s ease-in-out forwards .2s;
    }

    &.fade-up-4 {
      animation: fadeUp .5s ease-in-out forwards .3s;
    }

    &.fade-up-5 {
      animation: fadeUp .5s ease-in-out forwards .4s;
    }

    &.fade-up-6 {
      animation: fadeUp .5s ease-in-out forwards .5s;
    }
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }

  /*アニメーション終了時は不透明度100%*/
}
