$scroll-padding: 15px !default;
$content-height: 498px !default;
// $content-detail-height: 373px !default;

.top-news {
  position: relative;
  padding: 57px 0 112px;
  background-color: #fff;
  text-align: center;
  &__inner {
    margin: 0 auto;
    max-width: 780px;
  }
  &__heading {
    color: $light-navy;
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 30px;
    font-family: $font-josefin;
    line-height: 1;
  }
  &__list {
    overflow: auto;
    margin-top: 42px;
    margin-right: -4%;
    padding-right: 4%;
    text-align: left;

    @include min-M {
      margin-right: -103px;
      padding-right: 103px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #b5b5b5;
      box-shadow: inset 0 0 0 2px #fff;
    }
  }
  &__item {
    border-top: 1px solid $tint-blue;
    &:last-child {
      border-bottom: 1px solid $tint-blue;
    }
  }
  &__item-date {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-right: 8px;
    min-width: 103px;
    letter-spacing: .05em;
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 12px;
    line-height: 2.4;

    @include min-M {
      padding-bottom: 6px;
      min-height: 44px;
      font-size: 14px;
      line-height: inherit;
    }
  }
  &__item-heading {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    line-height: 1.5;

    @include min-M {
      padding-top: 6px;
      min-height: 44px;
    }
    &:hover {
      color: $light-navy;
    }
  }

  &__input {
    display: none;
  }

  &__toggle {
    @include min-M {
      display: flex;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    width: 100%;
    height: 100%;
    cursor: default !important;
  }
  &__window-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: rgba($black,.2);
    pointer-events: none;
  }
  &__window {
    position: relative;
    padding: 56px 0 24px 30px;
    max-width: 800px;
    width: 100%;
    height: 80%;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(#65778b,.2);
    font-size: 14px;
    line-height: 1.7;
    pointer-events: all;

    @include min-M {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 135px;
      min-height: 600px;
      height: auto;
    }
  }
  &__window-heading {
    position: absolute;
    top: 20px;
    left: 30px;
    color: $navy;
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 16px;
    font-family: $font-josefin;

    @include min-M {
      top: 30px;
      left: 40px;
      font-size: 17px;
    }
  }
  &__window-close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: inline-block;
    width: 46px;
    height: 46px;
    border-radius: 10px;
    background-color: transparent;
    background-image: url(/assets/img/index/icon-close.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: background-color .2s;

    @include min-M {
      top: 20px;
      right: 20px;
    }
    &:hover {
      background-color: $tint-blue;
    }
  }
  &__window-content {
    display: flex;
    overflow: auto;
    flex-direction: column;
    padding-right: 24px;
    height: 100%;

    @include min-M {
      padding-right: 82px;
      height: 537px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #b5b5b5;
      box-shadow: inset 0 0 0 2px #fff;
    }
  }
  &__window-content-date {
    color: $light-navy;
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 12px;
    line-height: 2.2;

    @include min-M {
      font-size: 14px;
      line-height: inherit;
    }
  }
  &__window-content-heading {
    margin-bottom: 11px;
    padding-bottom: 7px;
    border-bottom: 1px solid $tint-blue;
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 18px;

    @include min-M {
      margin-top: 4px;
      margin-bottom: 20px;
      padding-bottom: 17px;
      font-size: 22px;
    }
  }
  &__window-content-detail {
    flex-grow: 1;
    padding-bottom: 20px;
    border-bottom: 1px solid $tint-blue;
    letter-spacing: .05em;
    line-height: 1.8;
    p+p {
      margin-top: 11px;
    }
  }
  &__input:checked~.top-news__window-wrap {
    display: flex;
  }
  &__input:checked~.top-news__overlay {
    display: block;
  }
  &__window-content-link {
    text-decoration: underline;
  }
  &__table {
    margin: 11px 0;
    width: 100%;
    border-spacing: 0 5px;
    border-collapse: separate;

    @include min-M {
      th,
      td {
        display: table-cell;
      }
      th {
        width: 22%;
      }
    }
    th,
    td {
      display: block;
    }
  }
  &__logo-isms {
    display: block;
    margin: 0 auto;
    max-width: 300px;

    @include min-M {
      display: inline-block;
    }
  }
  &__logo-trustdock {
    display: block;
    margin: 11px auto;
    max-width: 300px;

    @include min-M {
      display: inline-block;
    }
  }
  &__logo-salesforce {
    display: block;
    margin: 11px auto;
    max-width: 250px;

    @include min-M {
      display: inline-block;
    }
  }
  &__notes {
    margin-right: 8px;
    vertical-align: top;
    font-size: .7em;
  }
  &__notes-area {
    @include min-M {
      margin-top: 5px;
    }
  }
  &__sub-title {
    font-weight: 700;
  }
}

.is-modal-open {
  overflow: hidden;
}
