.top-message {
  position: relative;
  padding-top: 50px;
  background-color: #e4f7ff;

  @include min-M {
    padding-top: 120px;
    padding-bottom: 115px;
  }
  &+& {
    padding-top: 30px;
  }
  &__heading {
    margin-bottom: 40px;
    color: $color-heading;
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.3;

    @include min-M {
      font-size: 2.6rem;
    }
  }
  &__text-group {
    margin: 0 auto;

    @include min-L {
      margin: 0;
      max-width: 550px;
      font-size: 15px;
    }
    &--second {
      @include min-L {
        margin-left: 0;
        max-width: 540px;
      }
    }
  }
  &__text {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  &__container {
    margin: 0 auto;
    padding: 0 $container-padding 30px;
    max-width: calc( 600px + #{$container-padding} * 2);
    @include min-M {
      padding-bottom: 0;
    }
    @include min-L {
      margin: 0 auto;
      max-width: 1008px;
    }
  }
  &__container-item {
    &+& {
      margin-top: 40px;
      padding-top: 40px;
      border-top: 1px solid #b0ceda;

      @include min-M {
        margin-top: 90px;
        padding-top: 0;
        border-top: none;
      }
    }
  }
  &__contents {
    @include min-L {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &--reverse {
      @include min-L {
        flex-direction: row-reverse;
      }
    }
  }
  &__button-area {
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;

    @include min-M {
      margin-top: 60px;
      margin-bottom: 0;
    }
  }
}
