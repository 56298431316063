.workflow-structure {
  padding: 80px 25px 120px ;
  @include min-M {
    padding: 80px 125px 120px;
  }

  &__title {
    margin-bottom: 64px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }

  &__text-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    max-width: 768px;
    width: 100%;
  }
  &__icon-group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 45px auto 0;
    padding: 0 25px;
    max-width: 340px;
    width: 100%;
    @include min-M {
      flex-wrap: nowrap;
      gap: 40px;
      margin: 56px auto 0;
      padding: 0 40px;
      max-width: 768px;
    }
  }
  &__logo-img {
    width: 60px;
    height: 60px;
    @include min-M {
      width: 80px;
      height: 80px;
    }
  }
}
