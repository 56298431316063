.case-cta{
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  max-width: calc(#{$container-pc--default} + #{$container-padding} * 2);
  margin: 0 auto;
  @include min-M{
    padding: 0 $container-padding 80px;
  }
  &__inner{
    max-width: 780px;
    padding: 40px 20px;
    margin: 0 auto;
    background-color: $tint-blue;
    @include min-M{
      padding: 60px 20px;
      border-radius: 10px;
    }
  }
  &__text{
    font-size: 18px;
  }
  &__subtext{
    font-size: 15px;
    margin-top: 10px;
    line-height: 2;
    @include min-M{
      margin-top: 3px;
    }
  }
  &__button{
    color: #fff;
    background-color: $black;
    border: 1px solid $black;
    max-width: 280px;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    margin: 20px auto 0;
    font-size: 16px;
    height: 66px;
    @include min-M{
      margin-top: 15px;
      max-width: 334px;
      font-size: 18px;
    }
  }
  &__icon{
    content: "";
    display: inline-block;
      width: 32px;
      height: 32px;
    margin-right: 8px;
    background-image: url("/assets/img/module/icon-email.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}