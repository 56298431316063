.works-case {
  margin: 0 auto 50px;

  &__inner {
    padding: 0 25px;
    @include min-M {
      padding: 0;
    }
  }

  &__card-area {
    margin: 35px auto 0;
    max-width: 500px;
    @include min-M {
      padding: 0 25px;
      max-width: calc(1052px + 25px * 2);
    }
  }

  &__card-inner {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr;
    width: 100%;
    @include min-M {
      grid-template-columns: 1fr 1fr;
      // row-gap: 145px;
    }
  }

  // &__side {
  //   position: relative;
  //   -ms-flex: 0 0 100%;
  //       flex: 0 0 100%;
  //   padding-top: 4%;
  //   padding-bottom: 4%;
  //   max-width: 100%;
  //   @include min-M {
  //     -ms-flex: 0 0 50%;
  //         flex: 0 0 50%;
  //     padding-right: 4%;
  //     padding-left: 4%;
  //     max-width: 50%;
  //     // height: 417px;
  //   }
  // }

  &__text {
    margin-top: 40px;
    margin-bottom: 20px;
    @include min-M {
      margin-bottom: 70px;
      letter-spacing: .03em;
      font-size: 1.6rem;
    }
  }
}
