.contact-category {
  margin: 60px auto;
  max-width: 210px;

  @include min-M {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 85px;
    max-width: inherit;
  }
  &__toggle {
    display: none;
  }
  &__icon {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -3px;
    margin-right: 10px;
    min-width: 25px;
    width: 25px;
    height: 25px;
    border: 1px solid #000;
    border-radius: 50%;
    vertical-align: middle;
  }
  &__label {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    @include min-M {
      justify-content: center;
    }
    &:not(:first-child) {
      margin-top: 15px;

      @include min-M {
        margin-top: 0;
        margin-left: 40px;
      }
    }
  }
  &__toggle:checked+&__icon {
    &:after {
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #000;
      content: "";
    }
  }
}
