.top-illust {
  position: relative;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;

  @include min-L {
    margin: 0;
  }

  //優れたシステムは〜のイラスト
  &--smart-system {
    min-width: 310px;
    width: 310px;
    height: 200px;
    background-image: url("/assets/img/index/bg-illust-1.svg");

    @include max-SE {
      min-width: inherit;
      height: 172px;
    }

    @include min-M {
      margin-top: 85px;
    }

    @include min-L {
      margin-top: 120px;
      margin-right: 20px;
      width: 380px;
      height: 235px;
    }
  }

  //デザイン〜システム開発〜のイラスト
  &--onestop {
    min-width: 280px;
    width: 280px;
    height: 190px;
    background-image: url("/assets/img/index/bg-illust-2.svg");

    @include max-SE {
      min-width: inherit;
      height: 180px;
    }

    @include min-M {
      margin-top: 85px;
    }

    @include min-L {
      margin-top: 30px;
      margin-right: 50px;
      width: 384px;
      height: 265px;
    }
  }

  //動く人アイコン
  &__anime-icon {
    position: absolute;
    width: 30px;

    @include max-SE {
      width: 25px;
    }

    @include min-L {
      width: 38px;
    }

    &--1 {
      bottom: 28px;
      left: 132px;
      animation: scale-2 3s infinite;

      @include max-SE {
        left: 108px;
      }

      @include min-L {
        left: 157px;
      }
    }

    &--2 {
      right: 36px;
      bottom: 28px;
      animation: nod-2 3s infinite;
    }

    &--3 {
      bottom: 43px;
      animation: nod 3s infinite;

      @include min-L {
        bottom: 55px;
      }
    }

    &--4 {
      right: 37px;
      bottom: 43px;
      animation: bounce 3s infinite;

      @include min-L {
        right: 47px;
        bottom: 62px;
      }
    }

    &--5 {
      top: 32px;
      left: 130px;
      transform: scale(-1, 1);
      animation: scale 3s infinite;

      @include min-M {
        top: 32px;
        left: 132px;
      }

      @include min-L {
        top: 50px;
        left: 186px;
      }
    }
  }
}