.top-case {
  position: relative;
  z-index: 1;
  margin-bottom: -5px;
  padding-top: 50px;
  padding-bottom: 55px;
  background-color: #fff;
  @include min-M {
    padding-top: 100px;
    padding-bottom: 85px;
  }

  &__heading {
    margin-bottom: 30px;
    color: $color-point;
    text-align: center;
    font-weight: bold;
    font-size: 2.6rem;
    @include min-M {
      margin-bottom: 50px;
      font-size: 3rem;
    }
  }

  &__description {
    margin-bottom: 40px;
    @include min-M {
      margin-bottom: 50px;
    }
  }

  &__slider {
    margin: 0 auto;
    max-width: 1236px;
  }

  .tag-case {
    margin-left: 18%;
  }

  &__button-area {
    margin-top: 55px;
    margin-bottom: 35px;
    text-align: center;
  }
}
