.case-member {
  margin-bottom: 60px;

  @include min-M {
    margin-bottom: 120px;
  }

  &--margin-top {
    margin-top: 40px;
  }

    &__list {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }

    &__item {
      min-width: 140px;
    }

    &__title {
      padding-bottom: 8px;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__text {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.6;
    }
}
