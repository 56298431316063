$black: #242424 !default;
$light-black: #303344 !default;
$dark-black: #101326 !default;
$navy-black: #1f212c !default;
$navy: #13255c !default;
$light-navy: #3b3eb4 !default;
$blue: #4778da !default;
$light-blue: #87a7e7 !default;
$light-red: #ff7373 !default;
$gray: #aaa !default;
$tint-blue: #eff1f7 !default;
$light-pink: #fff1f7 !default;
$red: #f00 !default;
$green: #83cf9e !default;
$yellow: #f2ed64 !default;
$light-green: #bbef92 !default;
$sky-blue: #9ad5ff !default;
$bg-blue: #ebf0fc !default;
$light-blue-num: #b0c3ef !default;
/* color */
$color-base: $light-black !default;
$color-point: $navy !default;
$color-heading: $navy !default;
$color-sub-heading: $light-navy !default;
$color-link: $light-black !default;
$color-error: $red !default;
$color-form: $blue !default;
$color-form-text: $light-black !default;
$color-footer: $navy-black !default;
$color-box: $tint-blue !default;
$color-comma: #dfe3ea;
$color-info: $light-pink !default;
/* case background */
$color-case-bank-backend: $yellow !default;
$color-case-residents-dx: $light-green !default;
$color-case-office-search: #f1b880 !default;
$color-case-customer-cloud: $light-blue !default;
$color-case-stay-dx: $light-red !default;
$color-case-estimate-cloud: #69d6a1 !default;
$color-case-employment-site: $sky-blue !default;
$color-case-corporate-site: #ff7765 !default;
$color-case-parking-cashless: #a698ff !default;
$color-case-finance-form: #ddec32 !default;
$color-case-withtalk-web: #8dd5e5 !default;
$color-case-tumugu-site: #ccdce5 !default;
$color-case-newspaper-renewal: #ffbbc1 !default;
$color-case-4: #69d6a1 !default;
/* tag */
$color-tag: $light-blue !default;
$color-case-label: $light-black !default;
/* bordercolor */
$border-color: $gray !default;
$border-point: $navy !default;

//変数にしてない色
// .hoge {
//   color: #b0c3ef; //BUSINESSFLOWのタイトル色
//   color: #e4f7ff; //トップのセカンドビューの背景
//   color: #97a2c4; //WORKSの実績のコメント欄を囲うボーダー
//   color: #fff1f7; //会社概要の背景ピンク
//   color: #c1c8d5; //case1,2,3のその他の事例
// }
