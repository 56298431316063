.post {
  margin: auto;

  &__header {
    position: relative;
    word-break: break-all;
  }
  &__inner {
    margin: auto;
    padding-top: 115px;
    max-width: 780px;
    width: calc(100% - 50px);
  }
  &__breadcrumbs {
    font-size: 12px;
    a {
      color: $navy;
      font-weight: bold;
      font-family: "Josefin Slab";
    }
  }
  &__arrow {
    &::after {
      position: relative;
      top: -1px;
      padding-left: 5px;
      content: ">";
    }
  }
  &__title {
    margin: 45px 0 25px;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.5;
  }
  &__category {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }
  &__category-item {
    padding: 5px 10px;
    border: solid 1px $gray;
    border-radius: 50px;
    background-color: #fff;
    font-size: 13px;
    line-height: 1;
  }
  &__underline {
    margin: 15px 0;
  }
  &__date {
    margin-left: 5px;
    letter-spacing: 1px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
  }
  &__container {
    margin: auto;
    padding: 50px 0 35px;
    max-width: 780px;
    width: calc(100% - 50px);
    border-bottom: 1px solid $gray;
    word-break: break-all;
    p,
    div {
      margin-bottom: 15px;
    }
    a {
      text-decoration: underline;
      word-break: break-all;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      list-style-type: disc;

      padding-inline-start: 25px;
    }
  }
  &__button-wrap {
    padding: 50px 0;
    text-align: center;
  }
  &__media {
    width: 100%;
    &--large {
      max-width: 600px;
    }
    &--medium {
      max-width: 500px;
    }
    &--small {
      max-width: 400px;
    }
    &--x-small {
      max-width: 300px;
    }
    &--border {
      border: solid 1px rgba($color: $black, $alpha: .2);
      border-radius: 4px;
    }
  }
}
