.ui-design-works {
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 100px;

    gap: 42px;
    @include min-M {
      padding-bottom: 160px;
    }
  }
  &__title {
    color: $navy ;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 1;

    @include min-M {
      font-size: 20px;
    }
  }
  &__word {
    color: $light-navy;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .12em;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    font-family: "Josefin Slab";
    line-height: 1.2;
    opacity: .5;

    @include min-M {
      font-size: 34px;
    }
  }
  &__wrap {
    display: flex;
    flex-direction: column;

    gap: 30px;
  }
  &__card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px 25px;
    max-width: initial;
    width: 100%;
    border-radius: 10px;

    gap: 30px;
    @include min-M {
      align-items: flex-start;
      flex-direction: row;
      padding: 32px 40px;
      max-width: 981px;

      gap: 52px;
    }
  }
  &__img {
    max-width: 444px;
    width: 100%;
  }
  &__link {
    @include min-M {
      flex: 1;
    }
  }
  &__text-group {
    max-width: initial;

    @include min-M {
      flex: 2;
      max-width: 405px;
    }
  }
  &__card-title {
    margin-bottom: 16px;
    color: $light-navy;
    letter-spacing: .04em;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 1.5;

    @include min-M {
      font-size: 20px;
    }
  }
  &__card-title--small {
    @include min-M {
      font-size: 18px;
    }
  }
  &__list-category {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @include min-M {
      margin-bottom: 32px;
    }
  }
  &__list-item {
    padding-right: 12px;
    color: $light-black;
    letter-spacing: .04em;
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
    line-height: 1.6;

    @include min-M {
      font-size: 14px;
    }
    &:not(:first-of-type) {
      padding: 0 12px;
      border-left: 1px solid rgba(48, 51, 68, .20);
    }
  }
  &__list-text {
    color: $light-black;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    line-height: 1.9;
  }
  &__card-text {
    color: $light-black;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 1.9;
  }
}
