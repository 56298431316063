.privacy-section {
	position: relative;
	margin-bottom: 24px;
	padding-bottom: 24px;
	border-bottom: 1px solid $tint-blue;
	&:not(:first-child) {
		counter-increment: countSection;
	}

	&__heading {
		margin-bottom: 24px;
		font-weight: bold;
		font-size: 15px;
		&--top {
      line-height: 1.5;
			font-size: 20px;
		}
		&--order {
			&:before {
				left: -1.5em;
				content: counter(countSection) ". ";
				text-align: center;
			}
		}
		&--the {
			&:before {
				left: -1.5em;
				content: "第"counter(countSection) "条";
				text-align: center;
			}
		}
	}
	p+p {
		margin-top: .5em;
	}
}
