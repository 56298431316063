.contact-thanks {
  text-align: center;
  max-width: 440px;
  margin: 0 auto;

  &__heading {
    margin-top: 70px;
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 1.8rem;
  }

  &__text {
    font-size: 1.5rem;
  }

  &__notes {
    margin-top: 50px;
    font-size: 1.3rem;
    text-align: center;
  }

  &__company {
    margin-top: 50px;
    font-size: 1.8rem;

    @include min-M {
      margin-top: 80px;
    }
  }
}