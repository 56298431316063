.case-site {
  $this: &;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 70px;
  margin-bottom: 77px;
  @include min-M {
    margin-top: 120px;
  }

  &__frame {
    background: rgba(16, 19, 38, .05);
  }

  &__list {
    display: flex;
    justify-content: center;
    max-width: 1104px;
    padding: 40px 25px;
    margin: auto;
    @include min-M {
      padding: 142px 20px 117px;
      justify-content: space-between;
    }
    &--pc {
      column-gap: 20px;
      row-gap: 20px;
      @include min-L {
        column-gap: 44px;
      }
      #{$this}__item:not(:first-child) {
        display: none;
        @include min-M {
          display: block;
        }
      }
    }
    &--sp {
      align-items: flex-start;
      column-gap: 10px;
      @include min-M {
        column-gap: 20px;
      }
      @include min-L {
        column-gap: 65px;
      }
      #{$this}__item:not(:first-child) {
        display: none;
        @include min-M {
          display: block;
        }
      }
    }
  }

  &__item {
    &--pc2 {
      margin-top: 125px;
    }

    &--pc3 {
      margin-top: 257px;
    }

    &--sp2 {
      margin-top: 63px;
    }

    &--sp3 {
      margin-top: 127px;
    }

    &--second {
      margin-left: -20px;
    }
  }

  &__caption {
    position: relative;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 22px;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 8px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 4px 0 4px;
      border-color: #303344 transparent transparent transparent;
    }
  }

  &__side {
    display: flex;
    justify-content: space-between;
  }

  &__img {
    max-width: 100%;
    border: solid 1px rgba($color: $black, $alpha: .2);
    border-radius: 4px;
    vertical-align: top;

    &--border-none {
      border: none;
    }

    &--second {
      margin-top: 90px;
      margin-left: 9px;

      @include min-M {
        margin-top: 130px;
        margin-left: 9px;
      }
    }
  }
}
