.case-detail {
  margin: 0 auto;
  padding-bottom: 50px;
  max-width: calc(#{$container-tb--default} + #{$container-padding} * 2);
  @include min-L {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -30px;
    margin: 0 auto;
    padding-bottom: 66px;
    max-width: calc(#{$container-pc--default} + #{$container-padding} * 2);
    &--bank-backend {
      padding-bottom: 98px;
    }
  }

  &__box {
    padding: 30px 25px;
    background-color: $color-box;
    @include min-M {
      padding-right: 45px;
      padding-left: 45px;
      min-width: 511px;
      border-radius: 10px;
    }
  }

  &__box-item {
    & + & {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid $border-color;
      @include min-M {
        margin-top: 15px;
        padding-top: 15px;
      }
    }
  }

  &__item {
    @include min-L {
      padding: 0 30px;
      width: 50%;
    }
  }

  &__heading {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.8;
    &:nth-child(3) {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid rgba(16, 19, 38, .2);
    }
  }

  &__text {
    & + & {
      margin-top: 20px;
    }

    & + .case-detail__heading {
      margin-top: 15px;
    }
  }

  &__list {
    &--count {
      list-style: none;
      counter-reset: number 0;
    }
  }

  &__list-item {
    padding-left: 1.5em;
    text-indent: -1.5em;
    font-size: 15px;
    line-height: 1.9;
    &:before {
      margin-right: 10px;
      content: "・";
    }
    &--count {
      &:before {
        content: counter(number) " ";      /* 表示形式を指定 */
        counter-increment: number 1;      /* number カウンタの増加数をセット */
      }
    }
  }

  &__figure {
    max-width: 100%;
    width: 600px;
    &--parking-cashless {
      max-width: 389px;
      width: 100%;
      display: block;
      margin: auto;
    }
    &--finance-form {
      max-width: 400px;
      width: 100%;
      display: block;
      margin: auto;
    }
  }

  &__figure-wrap {
    position: relative;
    margin-top: 50px;
    padding: 0 $container-padding;
    @include min-L {
      margin-top: 25px;
      padding: 0;
    }
  }

  &__img-title {
    margin-bottom: 34px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 190%;
    @include min-L {
      margin-top: -40px;
      &--residents-dx {
        padding-left: 60px;
      }
    }
  }

  &__img {
    &--padding {
      @include min-L {
        padding: 0 33px;
      }
    }
  }

  &__logo {
    position: absolute;
    right: $container-padding;
    bottom: 0;
    width: 8%;
  }
}
