.p-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__area-content {
    text-align: center;
  }
  &__heading {
    font-size: 70px;
    line-height: 1;
  }
  &__text {
    margin-top: 25px;
  }
  &__link {
    margin-top: 25px;
    text-decoration: underline;
    display: inline-block;
  }
}