//
// Media query
//

// iPhoneSEサイズ以下
$max-SE: 320px;
// 481px~
$min-S: 481px;
// 769px~1023px
$min-M: 769px;
// 1024px~
$min-L: 1024px;

// 320pxまで（iPhoneSE/5以下）
@mixin max-SE {
  @media screen and (max-width: $max-SE) {
    @content;
  }
}

// 481px~ タブレット小 / スマートフォン横 / iPad
@mixin min-S {
  @media screen and (min-width: $min-S) {
    @content;
  }
}

// 769px~ タブレット大 / iPad Pro
@mixin min-M {
  @media screen and (min-width: $min-M) {
    @content;
  }
}

// 1025px~ デスクトップ
@mixin min-L {
  @media screen and (min-width: $min-L) {
    @content;
  }
}

// ドウシテモのときに使う
@mixin min-($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}
