.service-flow {
  padding: 60px 0 54px;
  background-color: rgba(#7a9aed,.15);

  @include min-L {
    margin-bottom: 50px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &__inner {
    @include min-M {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 780px;
    }
    @include min-(1200px) {
      max-width: inherit;
    }
  }
  &__heading {
    color: #b0c3ef;
    letter-spacing: .12em;
    font-weight: 600;
    font-size: 2.6rem;

    @include min-L {
      text-align: left;
      font-size: 4rem;
    }
  }
  &__text-group {
    @include min-M {
      margin-top: 50px;
      width: 70%;
    }
    @include min-(1200px) {
      width: 60%;
    }
  }
  &__text {
    margin-top: 20px;
    line-height: 1.8;
  }
}
