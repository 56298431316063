.service-contents {
  background-color: $bg-blue;
  margin-top: -5px;
  padding: 70px 25px 80px;

  @include min-S {
    padding: 70px 30px 80px;
  }

  @include min-M {
    margin-top: 0;
    padding-top: 0;
  }

  &__inner {
    max-width: 980px;
    margin: auto;
    @include min-M {
      padding-top: 74px;
    }
  }

  &__heading {
    text-align: center;
  }

  &__heading-text {
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;

    @include min-M {
      font-size: 20px;
    }
  }

  &__heading-word {
    margin-top: 12px;
    color: $light-navy;
    font-family: Josefin Slab;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .13em;
    text-transform: uppercase;
    opacity: .5;

    @include min-S {
      margin-top: 0;
    }

    @include min-M {
      margin-top: 16px;
      font-size: 34px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 44px auto 0;
    max-width: 550px;
    row-gap: 30px;

    @include min-S {
      row-gap: 45px;
    }

    @include min-M {
      flex-direction: row;
      max-width: initial;
      row-gap: 40px;
      margin-top: 54px;
    }
  }

  &__item {
    flex-basis: 49%;
    position: relative;
    border-radius: 10px;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 111px;

    @include min-S {
      background-size: 133px;
    }

    &--1 {
      background-image: url(/assets/img/service/contents_01.png);
      background-size: 127px;

      @include min-S {
        background-size: 159px;
      }
    }

    &--2 {
      background-image: url(/assets/img/service/contents_02.png);
    }

    &--3 {
      background-image: url(/assets/img/service/contents_03.png);
    }

    &--4 {
      background-image: url(/assets/img/service/contents_04.png);
    }
  }

  &__item-inner {
    padding: 40px;

    @include min-S {
      padding: 33px 40px;
    }

    @include min-M {
      padding: 40px;
    }
  }

  &__no {
    position: absolute;
    width: 68px;
    top: -14px;
    left: 15px;

    @include min-S {
      width: 65px;
      top: -2px;
      left: 31px;
    }

    @include min-M {
      width: auto;
      top: -19px;
      left: 21px;
    }
  }

  &__title {
    color: $light-navy;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }

  &__text {
    margin-top: 14px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
  }

  &__button-area {
    margin-top: 32px;
    text-align: center;

    @include min-S {
      margin-top: 15px;
    }

    @include min-M {
      margin-top: 34px;
    }
  }

  &__button {
    width: auto;
    height: 36px;
    padding: 0 47px;
    font-size: 12px;
    line-height: 36px;

    &--disable {
      opacity: .2;

      &:hover {
        background-color: #fff;
        color: $color-base;
        opacity: .2;
      }
    }
  }
}