.service-firstview {
  position: relative;
  padding: 100px $container-padding 50px;
  background-color: rgba(#fff, .30);
  background-image: url("/assets/img/service/bg-fv.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: screen;
  @include min-M {
    padding: 115px 30px 97px;
    background-position: center;
  }
  &__inner {
    margin: 0 auto;
    max-width: 1024px;
  }

  &__sholder-text {
    margin-top: 50px;
    color: $color-base;
    text-align: center;
    @include min-M {
      margin-top: 50px;
      font-size: 1.6rem;
    }
  }

  &__lang-wrap {
    @include min-(1100px) {
      margin-left: -765px;
      background-image: url("/assets/img/service/fv-left-img.png");
      background-position: bottom;
      background-size: 535px;
      background-repeat: no-repeat;
    }
  }

  &__lang {
    margin: 0 auto;
    margin-top: 45px;
    max-width: 600px;
    @include min-L {
      max-width: 780px;
    }
    @include min-(1100px) {
      margin: 80px 0 0 1099px;
      max-width: 690px;
    }
  }

  &__lang-heading {
    margin-bottom: 20px;
    color: $color-sub-heading;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    @include min-M {
      font-size: 2.6rem;
    }
    @include min-L {
      text-align: left;
    }
  }

  &__lang-text {
    margin-bottom: 40px;
    @include min-L {
      margin-bottom: 30px;
    }
  }

  &__br {
    @include min-L {
      display: none;
    }
  }

  &__list-inner {
    margin: 38px auto 0;
    max-width: 500px;
    @include min-M {
      max-width: 836px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin: auto;
    @include min-M {
      row-gap: 55px;
    }
  }

  &__item {
    @include min-M {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
    }

    &:nth-child(2n) {
      @include min-M {
        flex-direction: row-reverse;
      }
    }
  }

  &__content {
    max-width: 490px;
  }

  &__title {
    margin-bottom: 24px;
    color: $light-navy;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    @include min-M {
      text-align: left;
      font-size: 26px;
    }
  }

  &__title-sp {
    @include min-S {
      display: none;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.9;
    @include min-M {
      font-size: 15px;
    }
  }

  &__img {
    display: block;
    margin: 20px auto 50px;
    max-width: 200px;
    width: 60%;
    @include min-M {
      max-width: initial;
      width: auto;
    }

    &--01 {
      @include min-M {
        margin: 0 40px 0 0;
      }
    }

    &--02 {
      @include min-M {
        margin: 0 0 0 15px;
      }
    }

    &--03 {
      @include min-M {
        margin: 0 14px 0 0;
      }
    }
  }
}
