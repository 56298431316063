.news {
  position: relative;
  margin: auto;
  padding-bottom: 115px;
  background-color: #fff;
  text-align: center;

  &__inner {
    margin: 0 auto;
    max-width: 780px;
  }
  &__heading {
    letter-spacing: .05em;
    font-weight: bold;
    font-size: 30px;
    font-family: $font-josefin;
    line-height: 1;
    &--navy {
      color: $light-navy;
    }
  }
  &__list {
    margin-top: 50px;
    text-align: left;
    &--large {
      margin-top: 80px;
    }
  }
  &__item {
    border-top: 1px solid $tint-blue;
    &:last-child {
      border-bottom: 1px solid $tint-blue;
    }
    a:hover span {
      color: $light-navy;
    }
  }
  &__item-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px 0;
    @include min-L {
      flex-wrap: nowrap;
      gap: 20px;
    }
    &--large {
      padding: 30px 0;
    }
  }
  &__date {
    font-weight: bold;
    font-size: 13px;
  }
  &__category {
    display: flex;
    gap: 5px;
    @include min-S {
      flex-direction: column;
    }
    span {
      padding: 3px 5px;
      min-width: 103px;
      border: solid 1px $gray;
      border-radius: 50px;
      background-color: #fff;
      text-align: center;
      white-space: nowrap;
      font-size: 13px;
      line-height: 1;
    }
    &--row {
      flex-direction: row;
      span {
        min-width: auto;
        white-space: wrap;
      }
    }
  }
  &__title {
    width: 100%;
    word-break: break-all;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    @include min-L {
      width: auto;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__card {
    width: 100%;
    border: 1px solid $tint-blue;
    text-align: left;
    @include min-M {
      width: calc(50% - 10px);
    }
    @include min-L {
      width: calc(33.33% - 15px);
    }
    a {
      display: block;
      padding: 15px;
      height: 100%;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
  }
  &__img {
    max-height: 120px;
    width: 100%;

    object-fit: cover;
  }
  &__button-wrap {
    margin-top: 60px;
  }

  &--padding-top {
    padding-top: 115px;
  }
}
