.member-contents {
  &__inner {
    margin: 80px auto;
    width: 100%;
    @include min-S {
      margin: 80px auto 180px;
      max-width: 712px;
    }
  }
  &__list {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 0;
    @include min-S {
      gap: 40px 56px;
    }
  }
  &__item {
    display: block;
    max-width: 160px;
    width: 100%;
    text-align: center;
    transition: all .3s ease;
    @include min-S {
      max-width: 200px;
    }
    &:focus-visible {
      outline: 1px solid $navy;
      border-radius: 10px;
    }
    &:hover,
    &:focus {
      opacity: .7;
    }
  }
  &__title {
    display: block;
    margin-top: 16px;
    color: $navy;
    font-weight: 700;
    font-size: 20px;
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    @include min-S {
      font-size: 18px;
    }
  }
  &__text {
    display: block;
    margin-top: 8px;
    font-size: 10px;
    line-height: 1.7;
    @include min-S {
      font-size: 12px;
    }
  }
  &__img {
    width: 100%;
  }
}
