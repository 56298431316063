@charset "utf-8";

/*
setting
*/
@import "setting/_color.scss"; //フォントとカラー変数
@import "setting/_global.scss"; //イラストのサイズ

/*
mixin
*/
@import "mixin/_mediaquery.scss"; //メディアクエリ
@import "mixin/_flexbox.scss"; //フレックスボックス
@import "mixin/_absolute.scss"; //ポジション
/*
base
*/
@import "base/_reset.scss"; //リセット
@import "base/_base.scss"; //ベース

/*
module
*/
@import "module/_main.scss"; //メイン
@import "module/_container.scss"; //コンテナ

@import "module/_header.scss"; //ヘッダー
@import "module/_navi.scss"; //ナビゲーション
@import "module/_footer.scss"; //フッター
@import "module/_footer-contact.scss"; //お問い合わせ

@import "module/_breadcrumb.scss"; //ぱんくず
@import "module/_order-list.scss"; //順番リスト
@import "module/_heading.scss"; //見出し
@import "module/_icon-link.scss"; //リンク
@import "module/_button.scss"; //ボタン
@import "module/_tag.scss"; //タグ
@import "module/_label.scss"; //タグ
@import "module/_case-card.scss"; //ボックス
@import "module/_description.scss"; //テキストブロック
@import "module/_row-col.scss"; //row col
@import "module/_swiper-slider.scss"; //スライダー
@import "module/_margin.scss"; //余白
@import "module/_accordion.scss"; //アコーディオン
@import "module/_modal.scss"; //モーダル


@import "module/_position-sticky.scss";
@import "module/_utility.scss"; //inline-blockなど
@import "module/_fade-in.scss";


/*
page
ページ特有のBlock
{ページディレクトリ名}/scssファイル とする
*/

/* index */
@import "page/top/_top-first-view.scss"; //ファーストビュー
@import "page/top/_top-second-view.scss"; //私たちにできること
@import "page/top/_top-message.scss"; //いいシステムで〜・デザイン〜
@import "page/top/_top-case.scss"; //開発事例
@import "page/top/_top-works.scss"; //その他実績
@import "page/top/_appear-device.scss"; //左右からスライドしてくるデバイス
@import "page/top/_top-keyframes.scss"; //keyframes
@import "page/top/_top-illust.scss"; //イラスト
@import "page/top/_top-news.scss"; //お知らせ
@import "page/top/_comment.scss"; //topの人アイコンと吹き出しのセット
/* news */
@import "page/news/_news.scss"; //お知らせ一覧
@import "page/news/_post.scss"; //記事詳細
/* service */
@import "page/service/_service-firstview.scss"; //ファーストビュー
@import "page/service/_service-flow.scss"; //ビジネスフロー
@import "page/service/_service-feature.scss"; //サービスの特徴
@import "page/service/_service-bottom.scss"; //下部
@import "page/service/_language-group.scss"; //雲のアニメーション
@import "page/service/_flow-anime.scss"; //ビジネスフローアニメーション
@import "page/service/_service-contents.scss"; //主なサービス
@import "page/service/_service-others.scss"; //他にこんな事もできます
@import "page/service/_salesforce.scss"; //salesforce
@import "page/service/_salesforce-firstview.scss"; //salesforceファーストビュー
@import "page/service/_salesforce-contents.scss"; //salesforce開発支援でできること
@import "page/service/_salesforce-example.scss"; //salesforce開発事例
@import "page/service/_mobile-app-firstview.scss"; //mobile-appファーストビュー
@import "page/service/_mobile-app-quality.scss"; //mobile-appクラウドネイティブの3つの特徴
@import "page/service/_mobile-app-advantage.scss"; //mobile-appクラウドネイティブ開発のSORICHの強み
@import "page/service/_mobile-app-faq.scss"; //mobile-appよくある質問
@import "page/service/_mobile-app-works.scss"; //mobile-appクラウドネイティブ 開発事例
@import "page/service/_cloud-native-firstview.scss"; //cloud-nativeファーストビュー
@import "page/service/_cloud-native-quality.scss"; //cloud-nativeクラウドネイティブの3つの特徴
@import "page/service/_cloud-native-advantage.scss"; //cloud-nativeクラウドネイティブ開発のSORICHの強み
@import "page/service/_cloud-native-faq.scss"; //cloud-nativeよくある質問
@import "page/service/_cloud-native-works.scss"; //cloud-nativeクラウドネイティブ 開発事例
@import "page/service/_ui-design-firstview.scss"; //ui-designファーストビュー
@import "page/service/_ui-design-onestop.scss"; //ui-designワンストップの強み
@import "page/service/_ui-design-works.scss"; //ui-design UI実績のご紹介
/* works */
@import "page/works/_works-case.scss"; //ファーストビュー
@import "page/works/_performance-list.scss"; //その他開発実績
/* case */
@import "page/case/_case-firstview.scss"; //ファーストビュー
@import "page/case/_case-summary.scss"; //概要
@import "page/case/_case-detail.scss"; //事例詳細
@import "page/case/_case-notes.scss"; //補足コメント
@import "page/case/_case-img.scss"; //画像
@import "page/case/_case-voice.scss"; //開発者の声
@import "page/case/_case-others.scss"; //そのほかの事例
@import "page/case/_case-cta.scss"; //事例ページCTA
@import "page/case/_case-site.scss"; // サイト画面画像
@import "page/case/_case-link.scss"; // サイトリンク
@import "page/case/_case-member.scss"; // プロジェクトメンバー
/* company */
@import "page/company/_history.scss"; //会社の歴史
@import "page/company/_greetings.scss"; //代表挨拶
@import "page/company/_company-info.scss"; //会社概要
/* member */
@import "page/member/_member-firstview.scss"; //ファーストビュー
@import "page/member/_member-contents.scss"; //メンバーコンテンツ
@import "page/member/_member-modal.scss"; //モーダル
/* workflow */
@import "page/workflow/_workflow-firstview.scss"; //ファーストビュー
@import "page/workflow/_workflow-flow.scss"; //システム開発の流れ
@import "page/workflow/_workflow-method.scss"; //システム開発の方法
@import "page/workflow/_workflow-structure.scss"; //コミュニケーション体制
/* about */
@import "page/about/_about-firstview.scss"; //ファーストビュー
@import "page/about/_about-advantage.scss"; //SORICHの5つの強み
@import "page/about/_about-technology.scss"; //常に新しい技術を取り入れる姿勢
@import "page/about/_about-flow.scss"; //柔軟性を重視したプロジェクト管理
@import "page/about/_about-certifications.scss"; //社員の保有資格
@import "page/about/_about-works.scss"; //さまざまな言語での豊富な開発実績 開発事例
/* privacy */
@import "page/privacy/_privacy-content.scss";
@import "page/privacy/_privacy-section.scss"; //セクション
/* contact */
@import "page/contact/_contact-container.scss"; //コンテナサイズ
@import "page/contact/_contact-category.scss"; //ラジオボタン
@import "page/contact/_contact-form.scss"; //フォーム部分
@import "page/contact/_contact-confirm.scss"; //確認
@import "page/contact/_contact-thanks.scss"; //完了
@import "page/contact/_contact-button-area.scss"; //ボタン部分
/* not found */
@import "page/not-found/_not-found.scss"; //セクション
