.greetings {
  position: relative;
  padding: 60px 0;

  @include min-L {
    overflow: hidden;
    padding: 120px 0;
  }
  &__bg {
    display: none;

    @include min-L {
      z-index: -1;
      display: block;
      width: 65%;
      height: 686px;

      @include absolute($top: 0,$left: 60%,$right: 0);
    }
  }
  &__bg-inner {
    width: 100%;
    height: 100%;
    background: url("/assets/img/company/bg-greetings.jpg") -800px bottom;
    background-repeat: no-repeat;
  }
  &__inner {
    position: relative;
    margin: 0 auto;
    padding: 0 $container-padding;
    max-width: calc(600px + #{$container-padding} * 2);
    color: $color-point;

    @include min-L {
      max-width: 1100px;
    }
  }
  &__heading {
    font-weight: bold;
    font-size: 2.4rem;

    @include min-L {
      font-size: 3.2rem;
    }
  }
  &__sub-heading {
    display: block;
    margin: 0;
    letter-spacing: .01em;
    font-weight: bold;
    font-size: 1.6rem;

    @include min-L {
      font-size: 2rem;
    }
  }
  &__main {
    position: relative;
    padding-bottom: 180px;

    @include min-L {
      //margin-left: 7%;
      padding-bottom: 0;
      padding-top: 40px;
    }
  }
  &__ceo-name {
    margin-bottom: 10px;
    font-weight: bold;
    text-align: right;
    max-width: 530px;
  }
  &__text {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  &__img {
    &--sign {
      margin-top: 40px;
      width: 150px;
      height: 55px;

      @include min-L {
        margin-top: 0;
        margin-left: 300px;
      }
    }
    &--full-body-shot {
      position: absolute;
      left: 60%;
      bottom: 0;
      width: 120px;
      height: 242px;

      @include min-L {
        width: 260px;
        height: 524px;
      }
    }
  }
}
