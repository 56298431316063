.service-feature {
  margin-top: 42px;

  @include min-L {
    margin-top: 75px;
  }

  &__inner {
    margin: 0 auto;
    max-width: 660px;
    text-align: center;

    @include min-(1100px) {
      display: flex;
      justify-content: space-between;
      margin-bottom: -5px;

      max-width: 1024px;
      text-align: left;
    }
  }

  &__item {
    margin: 15px auto 20px;
    padding: 0 40px;
    max-width: 655px;
    opacity: 0;

    @include min-(1100px) {
      margin: 10px 0 0 0;
      padding: 0;
      width: 33.33%;
    }

    &:first-child {
      margin-top: 80px;
    }

    &+& {
      margin-top: 55px;
    }

  }

  &__num-wrap {
    @include min-L {
      height: 70px;
      margin-left: 20px;
    }
  }

  &__num {
    width: 58px;


    @include min-L {
      width: 80px;

    }

    // &--3 {
    //   height: 44px;

    //   @include min-L {
    //     height: 60px;
    //   }
    // }
  }

  &__heading {
    margin: 15px 0 10px;
    color: $color-sub-heading;
    font-weight: bold;
    font-size: 2rem;

    @include min-L {
      margin: 20px 20px 15px 60px;

    }
  }

  &__text {
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.7;

    @include min-L {
      margin-left: 60px;
      margin-right: 20px;
    }
  }

  &__mark-wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    @include min-(1100px) {
      display: none;
    }

    &--pc {
      display: none;

      @include min-(1100px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px auto;
        width: 1024px;
      }
    }
  }

  &__mark {
    position: relative;
    width: 20px;
    height: 40px;
    background-image: url("/assets/img/service/cross.svg");
    background-size: contain;
    background-repeat: no-repeat;

    @include min-L {
      width: 25px;
      height: 50px;
    }
  }
}