.mobile-app-advantage {
  @include min-M {
  }
  &__inner {
    padding: 80px 25px 80px;
    @include min-M {
      padding: 80px 25px 120px;
    }
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 26px;
    }
  }
  &__text {
    margin: 40px 0 0;
    text-align: center;
    font-size: 15px;
    line-height: 1.5;
    @include min-M {
      margin: 80px 0 0;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    @include min-M {
      max-width: 780px;
    }
  }
  &__list-item {
    width: 100%;
    margin: 40px 0 0;
    text-align: center;
    @include min-M {
      max-width: 33.3%;
      margin: 80px 0 0;
    }
  }
  &__list-title {
    margin: -18px 0 0;
    line-height: 1.5;
    font-weight: 700;
    font-size: 22px;
    color: $navy;
  }
}
