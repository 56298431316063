.tag {
  display: inline-block;
  padding: 0 10px;
  height: 25px;
  color: #fff;
  line-height: 26px;
  &--blue {
    margin-top: 5px;
    border-radius: 5px;
    background-color: $color-tag;
    font-size: 1.3rem;
  }
  &--case {
    background-color: $color-case-label;
    font-size: 1.4rem;
  }
}
