.comment {
  position: relative;


  &__text {
    font-size: 1.4rem;
    line-height: 1.5;

    &--border {
      padding: 10px 15px;
      border: solid 1px #97a2c4;
      border-radius: 10px;
      background-color: #fff;
      color: rgba(#222467, .7);
    }
  }

  &__speech-bubble {
    position: relative;
    margin-left: 60px;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;

    &:after {
      margin-left: -10px;
      width: 0;
      height: 0;
      border-width: 15px 10px 0 0;
      border-style: solid;
      border-color: transparent #fff transparent transparent;
      content: "";

      @include absolute($left: 0, $bottom: 20px);
    }
  }

  &__icon {
    z-index: 999;
    width: 40px;
    transform: scale(-1, 1);

    @include absolute($bottom: 0, $left: 0);
  }
}