.service-bottom {
  margin-top: 32px;
  margin-bottom: 56px;
  text-align: center;

  @include min-L {
    margin-top: 76px;
    margin-bottom: 118px;
  }
  &__img {
    max-width: 620px;
    width: 100%;
    vertical-align: bottom;
  }
  &__button-wrap {
    margin-top: 58px;
    text-align: center;

    @include min-L {
      margin-top: 80px;
    }
  }
}
