.company-info {
  padding: 60px 0 50px;
  background-color: #fff1f7;
  color: $color-point;
  letter-spacing: initial;

  @include min-M {
    padding: 120px 0;
  }

  &__container {

    @include min-M {

    }

    @include min-L {

    }
  }

  &__heading {
    margin-bottom: 80px;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;

    @include min-M {
      font-size: 3rem;
    }
  }

  &__main {
    display: block;
    max-width: calc(600px + 25px * 2);
    margin: auto;
    padding: 0 25px;
    @include min-L {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 100%;
      padding: 0;
    }
  }

  &__image {
    margin: 30px auto 10px;
    text-align: center;
    width: 100%;
    @include min-L {
      margin: 0;
      width: 45%;
    }

    &-photo{
      max-width: 100%;
      margin-bottom: 40px;
      @include min-L {
        width: 80%;
        margin-left: 20%;
        display: block;
      }
      &-others{
        width: 100%;
        display: none;
        margin-top: 20px;
        @include min-L {
          display: block;
        }
      }
    }
  }

  &__list {
    width: 100%;
    @include min-M {
    }

    @include min-L {
      width: 50%;
    }
  }

  &__list-item {
    display: flex;
    padding: 8px 0;
    border-top: 1px solid rgba($border-color, .3);
    font-size: 1.4rem;

    @include min-L {
      border: none !important;
      font-size: 1.5rem;
    }

    &:last-child {
      border-bottom: 1px solid rgba($border-color, .3);
    }

    &--address {
      flex-wrap: wrap;

      @include min-M {
        flex-wrap: nowrap;
      }
    }
  }

  &__list-heading {
    min-width: 85px;
    font-weight: normal;

    @include min-M {
      min-width: 100px;
    }
  }
  &__list-data{
    flex:1;
  }

  &__list-note{
    font-size: 80%;
    line-height: 1.4em;
    display: block;
    text-indent: -1em;
    padding-left: 1em;
    @include min-L {
      max-width: 500px;
    }
  }

  &__list-member{
    padding-left: 4em;
  }

  &__address {
    position: relative;
    line-height: 1.5;

    &:first-child {
      padding-top: 5px;
    }

    &+& {
      margin-top: 10px;

      @include min-M {
        margin-top: 15px;
      }
    }
  }



  &__address-link {
    position: absolute;
    bottom: -1px;
    width: 20px;
    height: 20px;

    @include min-M {
      width: 26px;
      height: 26px;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    vertical-align: bottom;

    @include min-M {
      width: 26px;
      height: 26px;
    }
  }
  &__isms {
    display: inline-block;
    padding-top: 5px;
    img {
      max-width: 100%;
      @include min-M {
        max-width: 300px;
      }
    }
  }
}

.u-ls-10 {
  letter-spacing: 10px;
}
