.cloud-native-firstview {
  position: relative;
  padding-top: 80px;
  padding-bottom: 96px;
  width: 100%;
  background-image: url("/assets/img/service/cloud-native/top-bg.png");
  background-size: 62%;
  background-repeat: no-repeat;
  @include min-M {
    background-size: 51%;
  }
  &__inner {
    padding: 0 25px;
  }

  &__wrap {
    margin: 0 auto 80px;
    max-width: 592px;
    width: 100%;
    @include min-M {
      margin: 80px auto;
    }
  }

  &__title {
    margin-top: 0;
    text-align: center;
    font-weight: 700;
    font-size: 2.6rem;
    @include min-M {
      font-size: 36px;
      line-height: 1;
    }
  }
  &__text {
    margin-top: 50px;
    font-size: 14px;
    @include min-M {
      text-align: center;
      font-size: 16px;
    }
  }
  &__lead {
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 26px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 16px auto 0;
    max-width: 756px;
    width: 100%;
    @include min-M {
      align-items: flex-start;
      flex-direction: row;
    }
  }
  &__item {
    padding-top: 40px;
    padding-bottom: 40px;
    @include min-M {
      padding-top: 0;
    }
    &--top-padding {
      @include min-M {
        padding-top: 40px;
      }
    }
  }
  &__img {
    max-width: 252px;
    width: 100%;
  }
  &__caption {
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    @include min-M {
      font-size: 20px;
    }
  }
  &__arrow-img {
    display: block;
    margin: auto;
    max-width: 946px;
    width: 100%;
  }
  &__group {
    margin: auto;
    padding-top: 32px;
    max-width: 508px;
    width: 100%;
  }
  &__heading {
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 28px;
    }
    &--large {
      padding-top: 32px;
      color: $light-navy;
      font-weight: 700;
      font-size: 23px;
      line-height: 1.5;
      @include min-M {
        font-size: 32px;
      }
    }
    &--right {
      text-align: right;
    }
  }
}
