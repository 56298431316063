.contact-form {
	&__item {
		position: relative;
		margin-top: 55px;
	}
	&__label {
		position: absolute;
		top: -10px;
		left: 0;
		display: block;
		display: flex;
		align-items: center;
		color: $color-form-text;
		color: rgba(#000, .67);
		font-weight: 500;
		font-size: 1.6rem;
		transition: all .2s;
		pointer-events: none;
	}
	&__required {
		margin-left: 10px;
		color: $red;
		vertical-align: middle;
		font-weight: bold;
		font-size: 11px;
		line-height: 1;
	}
	&__input,
	&__textarea {
		position: relative;
		width: 100%;
		border-bottom: 1px solid #000;
		font-size: 1.6rem;
		line-height: 1.5;
	}
	&__focus {
		position: absolute;
		bottom: -4px;
		left: 0;
		display: inline-block;
		visibility: hidden;
		width: 0;
		height: 5px;
		background-color: rgba($color-form,.5);
		transition: all .2s;
		pointer-events: none;
	}
	&__input,
	&__textarea {
		&:focus+.contact-form__label,
		&:not(:placeholder-shown)+.contact-form__label {
			top: -20px;
			color: $color-form;
			font-size: 1.2rem;
			.contact-form__required {
				margin: 5px;
				font-size: 10px;
				transform: scale(.8);
				transform-origin: left;
			}
		}
		&:focus~.contact-form__focus {
			visibility: visible;
			width: 100%;
			transition: all .2s;
		}
	}
	//エラーページのリストタイトル
	&__list {
		padding-bottom: 5px;
		border-bottom: 1px solid #000;
		&:not(:first-child) {
			margin-top: 10px;
		}
	}
	&__list-heading {
		color: $color-form;
		font-weight: bold;
		font-size: 1.2rem;
	}
	&__list-data {
		&--message {
			@include min-M {
				min-height: 350px;
			}
		}
	}
	&__error-message {
		background: lighten($color-error,45%);
		display: block;
		padding: 5px 8px;
		color: $color-error;
		font-weight: normal;
		font-size: 1.4rem;
	}
	&__recruit-message{
		margin-top: 20px;
	}
}
