.flow-anime {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-right: 5px;
  max-width: 315px;
  width: 110%;
  height: 160px;

  @include min-M {
    display: block;
    overflow: inherit;
    padding-right: 0;
    width: 30%;
    height: 340px;
  }
  @include min-(1200px) {
    width: 40%;
  }
  @include max-SE {
    width: 100%;
  }

  &__inner {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 28px;
    background-image: url("/assets/img/service/flow-anime-arrow.png");
    background-size: contain;
    background-repeat: no-repeat;

    @include min-M {
      width: 150px;
      height: 300px;
      background-image: url("/assets/img/service/flow-anime-arrow--vertical.png");
      background-position: center;
      background-size: 13%;
    }
  }

  &__text {
    position: absolute;
    bottom: 5px;
    margin-bottom: -6px;
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $font-josefin;
    line-height: 1;

    @include min-M {
      position: relative;
      bottom: inherit;
      text-align: center;
      font-size: 2.6rem;
    }

    &--goal {
      right: 0;
    }
  }

  &__figure-wrap {
    position: absolute;
    top: -44px;
    width: 100%;

    @include min-M {
      top: 10px;
    }
  }

  &__figure {
    position: relative;
    width: 115px;
    height: 115px;
    background-size: contain;
    background-repeat: no-repeat;

    @include min-M {
      width: 150px;
      height: 150px;
    }
    @include max-SE {
      width: 96px;
    }
  }

  &__figure-inner {
    position: absolute;
    top: 29px;
    left: 13px;
    width: 90px;

    @include min-M {
      top: 39px;
      left: 19px;
      width: 115px;
    }
    @include max-SE {
      top: 33px;
      left: 11px;
      width: 75px;
    }
  }
}

//jsのaddClassで発動
.spin-animation {
  animation: spin 4s ease-in-out .5s forwards;
}

.down-animation {
  animation: down-move-sp 4s ease-in-out .5s forwards;

  @include min-M {
    top: 10px;
    animation: down-move-pc 4s ease-in-out .5s forwards;
  }
  @include max-SE {
    animation: down-move-se 4s ease-in-out .5s forwards;
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(108deg);
  }
}

@keyframes down-move-se {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(160px);
  }
}

@keyframes down-move-sp {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(200px);
  }
}

@keyframes down-move-pc {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(120px);
  }
}
