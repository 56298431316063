.order-list {
  padding-left: 1.25em;
  list-style: decimal;
  &__item {
    margin-top: .75em;
  }

  &__item & {
    padding-left: 1.5em;
  }
  // 2層目
  &__item &__item,
  &__item--parenthesis {
    position: relative;
    list-style: none;
    counter-increment: secondLayer;

    &:before {
      position: absolute;
      left: -1.5em;
      content: "(" counter(secondLayer) ") ";
      text-align: center;
    }
  }

  // 3層目
  &__item &__item &__item,
  &__item--parenthesis &__item {
    position: relative;
    counter-increment: thirdLayer;

    &:before {
      position: absolute;
      top: .2em;
      left: -1.75em;
      width: 1.25em;
      content: counter(thirdLayer);
      text-align: center;
      font-weight: bold;
      font-size: .8em;
    }

    &:after {
      position: absolute;
      top: .3em;
      left: -1.5em;
      width: 1.2em;
      height: 1.2em;
      border: 1px solid #242424;
      border-radius: 50%;
      content: "";
    }
  }
  // 4層目
  &__item &__item &__item &__item,
  &__item--parenthesis &__item &__item {
    list-style: disc;
    counter-increment: inherit;
    &::before,
    &::after {
      display: none;
    }
  }
}
