.workflow-flow {
  padding-bottom: 200px;
  @include min-M {
    padding-bottom: 200px;
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 64px;
    padding: 80px 25px 64px;
    @include min-M {
      padding: 80px 125px 64px;
    }
  }

  &__title {
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  &__read {
    max-width: 768px;
    width: 100%;
  }
  &__img {
    max-width: 150px;
    width: 100%;
    height: auto;
    @include min-M {
      max-width: 1050px;
    }
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 48px;
    margin: auto;
    padding: 30px 25px 0;
    width: 100%;
    @include min-M {
      max-width: 768px;
    }
    @include min-L {
      padding: 0 80px;
      max-width: 1050px;
    }
  }
  &__box {
    width: 100%;
    @include min-L {
      width: 580px;
      &--left103 {
        margin-left: 103.33px;
      }
      &--left206 {
        margin-left: 206.66px;
      }
      &--left310 {
        margin-left: 310px;
      }
    }
  }
  &__group {
    margin-bottom: 16px;
  }
  &__number-img {
    margin-left: -16px;
  }
  &__sub-title {
    margin-top: -7px;
    color: $light-navy;
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
  }
  &__picture-one {
    position: absolute;
    top: 15px;
    right: 25px;
    width: 125px;
    height: 71px;
    @include min-L {
      top: 30px;
      right: 0;
      width: 280px;
      height: 160px;
    }
  }
  &__picture-two {
    position: absolute;
    bottom: -135px;
    left: 25px;
    width: 64px;
    height: 112px;
    @include min-L {
      bottom: 190px;
      left: 0;
      width: 128px;
      height: 224px;
    }
  }
  &__picture-three {
    position: absolute;
    bottom: -135px;
    left: 110px;
    width: 140px;
    height: 80px;
    @include min-L {
      bottom: -48px;
      left: 0;
      width: 280px;
      height: 160px;
    }
  }
  &__picture-img {
    width: 100%;
    height: auto;
  }
}
