.cloud-native-faq {
  background: $tint-blue;
  @include min-M {
  }
  &__inner {
    padding: 80px 25px;
  }
  &__wrap {
    margin: auto;
    max-width: 836px;
    width: 100%;
  }
  &__title {
    padding-bottom: 32px;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 26px;
    }
  }
  &__border {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 16px;
    max-width: 836px;
    width: 100%;
    border-bottom: 1px solid $navy;
  }
  &__label {
    position: relative;
    padding: 0 48px;
    color: $navy;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    @include min-M {
      font-size: 18px;
    }
    &::before,
    &::after {
      position: absolute;
      width: 32px;
      height: 32px;
    }
    &::before {
      left: 0;
      content: url("/assets/img/service/cloud-native/advantage-faq-img.svg");
    }
    &::after {
      top: 50%;
      right: 0;
      content: url("/assets/img/service/cloud-native/advantage-arrow-down-img.svg");
      transform: translateY(-50%);
    }
    &[aria-expanded="true"]::after {
      top: 0;
      transform: scale(1, -1)translateY(0);
    }
    &--after-none {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
  &__text {
    padding-bottom: 16px;
    padding-left: 48px;
    font-size: 15px;
    line-height: 1.6;
    @include min-M {
      font-size: 16px;
    }
  }
  &__link {
    text-decoration-line: underline;
  }
}
