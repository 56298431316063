.about-advantage {
  &__inner {
    padding: 80px 25px 120px ;
    @include min-M {
      margin: auto;
      padding: 80px 25px 120px ;
      max-width: 1030px;
      width: 100%;
    }
  }
  &__title {
    margin-bottom: 40px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.9;
  }

  &__img {
    width: 100%;
    &--width946 {
      display: block;
      margin: 32px auto 0;
      max-width: 946px;
    }
  }
  &__box {
    padding: 40px 0;
    border-bottom: 1px solid rgba(16, 19, 38, .20);

    &:last-child {
      border-bottom: none;
    }
  }
  &__heading {
    padding: 56px 0;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 1.5;
    &--large {
      color: $light-navy;
      font-size: 32px;
    }
  }
  &__group {
    display: flex;
    gap: 5px;
  }
  &__read-text {
    margin-top: 11px;
    color: $navy;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5;
    @include min-M {
      font-size: 24px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    @include min-M {
      flex-direction: row;
    }
  }
  &__item {
    width: 100%;
    &--width320 {
      max-width: 320px;
      @include min-M {
        margin-right: 40px;
      }
    }
  }
  &__text {
    margin-top: 28px;
    padding-left: 48px;
  }
  &__text-link {
    display: block;
    margin-top: 8px;
    padding-left: 48px;
    font-weight: 700;
    font-size: 14px;
    line-height: 2.57;

    text-decoration-line: underline;
  }
}
