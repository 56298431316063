.position-sticky {
  position: relative;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include min-(1024px) {
    position:         sticky;
    position: -webkit-sticky;
    height: 100vh;
  }
}
