.cloud-native-works {
  @include min-M {
  }
  &__inner {
    padding: 0 25px;
    @include min-M {
      padding: 0 32px;
    }
  }
  &__title {
    padding-top: 80px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }
  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 30px 0 80px;
    max-width: 1236px;
    width: 100%;
    @include min-M {
      padding-top: 48px;
    }
    @include min-L {
      flex-direction: row;
    }
  }
  &__card {
    width: 100%;
    @include min-L {
      width: 33.33%;
    }
  }
  &__card-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin: auto;
    padding: 32px;
    max-width: 412px;
  }
  &__img {
    width: 100%;
  }
  &__body {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
  &__label {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__label-item {
    padding: 6px 16px;
    border-radius: 5px;
    background: $light-black;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.1;
  }
  &__heading {
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    @include min-M {
      font-size: 22px;
    }
  }
  &__technology-list {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__technology-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0 8px;
    height: 24px;
    border: 1px solid $light-black;
    border-radius: 24px;
    font-size: 13px;
    line-height: 1.8;
  }
}
