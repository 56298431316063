.swiper-container--works,
.swiper-container--case {
  overflow: hidden;
  margin: 0 auto;
  max-width: 1324px;
  .swiper-slide {
    @include min-L {
      min-width: $card-width;
      max-width: $card-width;
    }
  }
}

.swiper-container--index,
.swiper-container--case {
  padding-bottom: 20px;
}

.swiper-wrapper {
  min-height: 198px;
}

.swiper-container--case {
  .swiper-wrapper {
    justify-content: center !important;
  }
}

.swiper-slide {
  max-width: $card-width;
  height: auto !important;
  opacity: 0;
  transform: translateY(20px);
  &.scrollin {
    opacity: 1;
    transition: transform .5s ease-in-out,
    opacity .5s ease-in-out;
    transform: translateY(0px);
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  margin: 0 6px;
  &-active {
    background-color: lighten($color-base, 10%);
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  position: inherit;
  bottom: inherit;
}

.swiper-container--case {
  .swiper-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    @include min-M {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
  }
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
