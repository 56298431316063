.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);

  &__container {
    position: relative;
    margin: 56px auto 23px;
    max-width: 500px;
    width: calc(100% - 46px);
    height: calc(100% - 79px);
    border-radius: 8px;
    background-color: #fff;
    @include min-M {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: auto;
      padding-top: 48px;
      max-width: 800px;
      max-height: 600px;
      transform: translate(-50%, -50%);
    }
  }
  &__scroll-box {
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 0;
    overflow-y: auto;
    padding: 48px 40px;
    width: calc(100% - 10px);
    height: 100%;
    transform: translate(-50%, -50%);
    @include min-M {
      padding: 0 48px 48px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #b5b5b5;
    }
  }
  &__close {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: 1;
    width: 22px;
    height: 22px;
    background: #fff;
    cursor: pointer;
    transition: all .3s ease;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    @include min-M {
      top: 15px;
      right: 15px;
      background: #10132633;
    }

    mask-image: url(../img/module/modal-close.svg);
    &:hover {
      opacity: .7;
    }
    &:focus-visible {
      outline: 1px solid $navy;
      border-radius: 10px;
    }
    & img {
      display: block;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: block;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      opacity: .7;
    }
    &:focus-visible {
      outline: 1px solid $navy;
      border-radius: 10px;
    }
  }
  &__prev {
    left: -23px;
    transform: scale(-1, 1)translateY(-50%);
  }
  &__next {
    right: -23px;
    transform: translateY(-50%);
  }
}
