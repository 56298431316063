%button,
.button {
  display: inline-block;
  padding: 0 40px;
  max-width: 100%;
  width: 300px;
  height: 53px;
  border: 1px solid $color-base;
  color: $color-base;
  text-align: center;
  letter-spacing: .1em;
  font-weight: 600;
  font-size: 1.8rem;
  font-family: $font-josefin;
  line-height: 53px;
  transition: background-color .3s;

  @include max-SE {
    width: 260px;
  }

  &:hover {
    background-color: $color-base;
    color: #fff;
    opacity: 1;
  }

  &--white {
    background-color: #fff;
  }

  &--ls {
    text-indent: 1em;
    letter-spacing: 1em;
  }

  &--japanese {
    font-family: $font-yu;
  }

  &--arrow {
    background-image: url("/assets/img/module/btn-arrow.svg");
    background-position: center right 40px;
    background-repeat: no-repeat;
    text-align: left;

    &:hover {
      background-color: $color-base;
      background-image: url("/assets/img/module/btn-arrow--white.svg");
    }
  }
}
