/**
* Base style
*/

$base-font-size--sp: 1.4rem !default;
$base-font-size--pc: 1.5rem !default;
$base-font-family: "游ゴシック体",
YuGothic,
"游ゴシック",
"Yu Gothic",
"メイリオ",
sans-serif !default;
$base-line-height: 1.9 !default;
$base-letter-spacing: .05em !default;
$base-font-color: $color-base !default;

html {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 62.5%;

  -ms-overflow-style: scrollbar;
  /* IE, Edge 対応 従来のスクロールバーを出す */
  scroll-behavior: smooth;
  /*スムーススクロール */
}

body {
  width: 100%;
  height: 100%;
  color: $base-font-color;
  // letter-spacing: $base-letter-spacing;
  font-size: $base-font-size--sp;
  font-family: $base-font-family;
  line-height: $base-line-height;
  /* iPhone等を横にしたときも、文字サイズが自動調整されないようにする */

  -webkit-text-size-adjust: 100%;

  @include min-M {
    font-size: $base-font-size--pc;
  }
}

select {
  -webkit-appearance: none;
  /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none;
  /* ベンダープレフィックス(Firefox用) */
  appearance: none;
  /* select要素のデフォルトの三角形を削除 */
}

table {
  text-align: left;
}


textarea {
  resize: none;
}

//type="number"の時の上下矢印を消す
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;

  -webkit-appearance: none;
}

//type="number"の時の上下矢印を消す(firefox,IE)
input[type="number"] {
  -moz-appearance: textfield;
}


label {
  user-select: none;
}

a {
  color: $color-link;
  transition: all .3s ease;

  &:hover {
    opacity: .7;
  }
}

[v-cloak] {
  display: none;
}