.footer {
	position: relative;
	padding: 40px 0;
	background-color: $color-footer;
	text-align: center;
	line-height: 1;

	&__copy {
		margin-top: 20px;
		color: #fff;
		letter-spacing: .08em;
		font-weight: 500;
		font-size: 1.3rem;
		font-family: $font-josefin;

		@include min-M {
			font-size: 1.4rem;
		}
	}

	&__copy-icon {
		font-size: 1rem;

		@include min-M {
			font-size: 1.4rem;
		}
	}

	&__sns {
		width: 25px;
		height: 25px;
		vertical-align: top;

		&:first-child {
			margin-right: 10px;
		}
	}

	&__sns-link {
		display: inline-block;
	}
	&__page-link {
		display: inline-block;
		margin-top: 20px;
		color: #fff ;
		text-decoration: underline;
		letter-spacing: .1em;
		font-weight: 500;
		font-size: 13px;
		font-family: $font-josefin;
	}
}
