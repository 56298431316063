.privacy-content {
	margin-top: 50px;
	padding: 40px 0;
	&+&{
		margin-top: 60px;
	}
	&:nth-child(odd) {
		background-color:$tint-blue;
	}
	&:last-child{
		padding-bottom: 150px;
	}
}
