/**
 * Container
 */

// 横幅いっぱいのときの左右の余白
$container-padding: 25px;

// 幅サイズ
$container-tb--default: 600px;
// PC 1025px~
$container-pc--default: 1024px;
$container-pc--small: 780px;

.container {
  position: relative;
  margin: 0 auto;
  padding-right: $container-padding;
  padding-left: $container-padding;
  max-width: calc(#{$container-tb--default} + #{$container-padding} * 2);
  width: 100%;

  @include min-L {
    max-width: calc(#{$container-pc--default} + #{$container-padding} * 2);
  }

  &--small {
    @include min-L {
      max-width: calc(#{$container-pc--small} + #{$container-padding} * 2);
    }
  }
}