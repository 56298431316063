.salesforce-contents {
    margin-top: 100px;

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;

    @include min-M {
      font-size: 26px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
    margin-top: 46px;

    @include min-M {
      align-items: unset;
      row-gap: 20px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include min-M {
      flex-direction: row;
      align-items: unset;
    }
  }

  &__text {
    position: relative;
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;

    @include min-S {
      width: 80%;
    }

    @include min-M {
      width: 354px;
      font-size: 16px;
    }

    &::before {
      display: block;
      position: absolute;
      width: 38px;
      height: 38px;
    }

    &--hope {
      padding: 24px 32px 16px;
      background: $tint-blue;
      color: $navy;
      font-weight: 500;
      letter-spacing: 0.05em;

      &::before {
        content: url("/assets/img/service/salesforce/icon-fumu.svg");
        top: -11px;
        left: -5px;
      }
    }

    &--result {
      padding: 21px 32px;
      border: 4px solid $tint-blue;
      color: $light-navy;
      font-weight: 700;
      letter-spacing: 0.01em;

      &::before {
        content: url("/assets/img/service/salesforce/icon-hirameki.svg");
        top: -16px;
        left: -10px;
      }
    }
  }

  &__img {
    padding: 0 10px;
    width: 52px;
    transform:rotate(90deg);
    z-index: 1;

    @include min-M {
      transform:rotate(0deg);
    }
  }
}