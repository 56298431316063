.member-modal {
  &__grid {
    display: grid;
    grid-template-areas: "photo"
    "text"
    "sns";
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    gap: 24px;
    @include min-M {
      grid-template-areas: "photo text"
      "sns text";
      grid-template-rows: max-content 1fr;
      grid-template-columns: 200px 1fr;
      gap: 32px 30px;;
    }
  }
  &__content-photo {
    grid-area: photo;
  }
  &__content-sns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-area: sns;
    gap: 24px;
    height: fit-content;
  }
  &__content-text {
    grid-area: text;
  }
  &__img {
    width: 100%;
    height: auto;
  }
  &__sns-item {
    img {
      display: block;
    }
    &--width63 {
      width: 63px;
      text-align: center;
      a {
        display: inline-block;
      }
    }
  }
  &__title {
    margin-bottom: 4px;
    color: $navy;
    text-align: center;
    letter-spacing: 1.1px;
    font-weight: 700;
    font-size: 22px;
    font-family: "Josefin Sans", sans-serif;
    line-height: normal;
    @include min-M {
      text-align: left;
    }
  }
  &__read {
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.7;
    @include min-M {
      margin-bottom: 0;
      text-align: left;
    }
  }
  &__text {
    font-size: 15px;
    @include min-M {
      margin-top: 24px;
    }
  }
  &__border {
    margin: 16px 0;
    border-color: #eff1f7;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.7;
  }
  &__list {
    list-style: disc;

    margin-inline-start: 20px;
  }
  &__list-item {
    margin-top: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.7;
  }
  &__link {
    text-decoration-line: underline;
  }
}
