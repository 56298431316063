.header {
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 20px;
  width: 100%;
  letter-spacing: .1em;
  font-family: $font-josefin;
  pointer-events: none;
  @include min-M {
    padding-right: 3%;
    padding-left: 3%;
  }

  &__link {
    display: inherit;
    pointer-events: auto;
  }

  &__logo {
    width: 100px;
    height: 23px;
    @include min-M {
      width: 135px;
      height: 30px;
    }
  }

  &__logo-inner {
    fill: rgb(39, 42, 59);
  }

  &__left {
    height: 19px;
    line-height: 0;
  }

  &__main {
    @include flex($justify: flex-end, $align: flex-start);
  }

  &__menu {
    display: none;
    @include min-L {
      display: flex;
      opacity: 1;
      transition: all .3s linear;
    }
  }

  &__menu-item {
    display: inline-block;
    letter-spacing: .2em;
    font-weight: bold;
    font-size: 13px;
    pointer-events: auto;
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  &__menu-link {
    position: relative;
    display: inline-block;
    color: $color-point;

    &--contact {
      padding: 6px 12px 4px;
      border: 0;
      background-color: #fff;
      color: $color-point;
      line-height: 1;

      &:after {
        display: none;
      }

      &:hover {
        background-color: $color-point;
        color: #fff;

        &:after {
          display: none;
        }
      }
    }

    &:after {
      position: absolute;
      bottom: 3px;
      left: 0;
      display: inline-block;
      width: 0;
      border-top: 2px solid rgba($color-point, .2);
      content: "";
    }

    &:not(:has(.header__menu-drop-border-link)):hover,
    &:not(:has(.header__menu-drop-border-link)):focus {
      opacity: 1;

      &:after {
        width: 100%;
        transition: all .2s;
      }
    }
  }

  &__menu-link-icon {
    display: flex;
    &::after {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: $color-point;
      content: "";

      mask-image: url(../img/module/icon-window.svg);
      mask-position: center center;
      mask-repeat: no-repeat;
      mask-size: contain;
    }
  }

  &__menu-drop {
    margin-left: 16px;
    line-height: 1.55;
    .header__menu-item {
      line-height: 1.75;
    }
  }

  &__menu-drop-list {
    position: absolute;
    display: flex;
    visibility: hidden;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 6px;
    margin-top: 10px;
    margin-right: -85px;
    padding: 12px 10px;
    min-width: 100px;
    width: fit-content;
    background-color: #fff;
    opacity: 0;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, .10));
    transition: all .3s;
    pointer-events: auto;

    &:hover {
      cursor: pointer;
    }

    &::before {
      position: absolute;
      top: -7px;
      left: 7px;
      display: block;
      width: 0;
      height: 0;
      border-width: 0 7px 7px 7px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
      content: "";
    }

    &::after {
      position: absolute;
      top: -10px;
      left: 0;
      display: block;
      width: 100%;
      height: 10px;
      background-color: #fff;
      content: "";
      opacity: 0;
    }
  }
  &__menu-drop-item {
    width: 100%;
    text-align: left;
  }
  &__menu-drop-item-link {
    display: block;
    letter-spacing: 0;
    font-size: 13px;
    font-family: $base-font-family;
    line-height: 2;
  }
  &__menu-drop-border-link {
    position: relative;
    &:after {
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $color-point;
      content: "";
      opacity: .3;
      transition: transform .3s ease;
      transform: scaleX(0);
      transform-origin: left;
    }
  }
}

.header__menu-drop:hover .header__menu-drop-list,
.header__menu-drop:focus-within .header__menu-drop-list {
  visibility: visible;
  opacity: 1;
}

body.news .header__menu-link--contact,
body.post .header__menu-link--contact,
body.works .header__menu-link--contact,
body.privacy .header__menu-link--contact {
  background-color: $color-point;
  color: #fff;

  &:hover {
    background-color: rgba($color-point, .4);
    color: $color-point;
  }
}

body.contact .header__menu-link--contact {
  pointer-events: none;

  &:hover {
    background-color: inherit;
    color: inherit;
  }
}

//カレントナビ
body.news .header__menu-link--news,
body.post .header__menu-link--news,
body.service .header__menu-link--service,
body.salesforce .header__menu-link--service,
body.ui-design .header__menu-link--service,
body.works .header__menu-link--works,
body.case .header__menu-link--works,
body.about .header__menu-link--about {
  pointer-events: none;

  &:after {
    position: absolute;
    bottom: 3px;
    left: 0;
    display: inline-block;
    width: 100%;
    border-top: 2px solid rgba($color-point, .2);
    content: "";
  }
  &:hover {
    cursor: default;
  }
}
body.service .header__menu-drop-item-link--service,
body.salesforce .header__menu-drop-item-link--salesforce,
body.mobile-app .header__menu-drop-item-link--mobile-app,
body.cloud-native .header__menu-drop-item-link--cloud-native,
body.ui-design .header__menu-drop-item-link--ui-design,
body.workflow .header__menu-drop-item-link--workflow,
body.about .header__menu-drop-item-link--about,
body.workflow .header__menu-drop-item-link--workflow,
body.company .header__menu-drop-item-link--company,
body.member .header__menu-drop-item-link--member {
  pointer-events: none;
}
body.service li:has(.header__menu-drop-item-link--service),
body.salesforce li:has(.header__menu-drop-item-link--salesforce),
body.mobile-app li:has(.header__menu-drop-item-link--mobile-app),
body.cloud-native li:has(.header__menu-drop-item-link--cloud-native),
body.ui-design li:has(.header__menu-drop-item-link--ui-design),
body.about li:has(.header__menu-drop-item-link--about),
body.workflow li:has(.header__menu-drop-item-link--workflow),
body.company li:has(.header__menu-drop-item-link--company),
body.member li:has(.header__menu-drop-item-link--member) {
  cursor: default;
}

body.contact .header__menu-link--contact {
  background-color: $color-point;
  color: #fff;
}

body.service .header__menu-drop-item-link--service .header__menu-drop-border-link::after,
body.salesforce .header__menu-drop-item-link--salesforce .header__menu-drop-border-link::after,
body.mobile-app .header__menu-drop-item-link--mobile-app .header__menu-drop-border-link::after,
body.cloud-native .header__menu-drop-item-link--cloud-native .header__menu-drop-border-link::after,
body.ui-design .header__menu-drop-item-link--ui-design .header__menu-drop-border-link::after,
body.about .header__menu-drop-item-link--about .header__menu-drop-border-link::after,
body.workflow .header__menu-drop-item-link--workflow .header__menu-drop-border-link::after,
body.member .header__menu-drop-item-link--member .header__menu-drop-border-link::after,
body.company .header__menu-drop-item-link--company .header__menu-drop-border-link::after,
.header__menu-drop-item-link:hover .header__menu-drop-border-link::after {
  transform: scaleX(1);
}

.header__menu-drop-item-link:not(:hover) .header__menu-drop-border-link::after {
  transition: none;
  transform: scaleX(0);
}

//ファーストビューあとにメニューを非表示に
.none {
  visibility: hidden;
  opacity: 0;
}

.top .is-white {
  .header__logo-inner {
    fill: #fff;
  }

  .header__menu-link {
    color: #fff;
    &.header__menu-drop-item-link {
      color: $navy;
    }


    &--contact {
      color: $color-point;

      &:hover {
        color: #fff;
      }
    }

    &:after {
      border-top: 2px solid rgba(#fff, .5);
    }
  }

  .header__menu-link-icon::after {
    background: #fff;
  }
}
