.salesforce-example {
  margin-top: 100px;
  margin-bottom: 100px;

  @include min-M {
    margin-top: 173px;
    margin-bottom: 142px;
  }

  &__title {
    color: $navy;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.9;
  }

  &__list {
    max-width: 1230px;
    margin: 50px auto 0;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 60px;

    @include min-M {
      margin: 80px auto 0;
      flex-direction: row;
      align-items: normal;
      justify-content: space-between;
      column-gap: 15px;
      row-gap: 0;
    }
  }

  &__item {
    width: 100%;
    max-width: 348px;
  }

  &__card {
    padding: 0;
  }

  &__card-label {
    @include min-M {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  &__card-title {
    @include min-L {
      font-size: 22px;
    }
  }

}