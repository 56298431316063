.language-group {
  position: relative;
  margin: 0 auto;
  padding-top: 50%;
  max-width: 380px;
  background-image: url("/assets/img/service/bg-language.svg");
  background-size: contain;
  background-repeat: no-repeat;

  @include min-S {
    padding-top: 0;
    max-width: 440px;
    height: 220px;
  }

  @include min-L {
    max-width: 600px;
    height: 300px;
  }

  @include min-(1100px) {
    margin: 0 0px 0 auto;
  }

  @include max-SE {
    margin: 0 -20px;
    padding-top: 0;
    height: 147px;
  }

  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    height: 75%;
    transform: translateX(-51%) translateY(-50%);

    @include min-L {
      width: 70%;
      height: 70%;
    }
  }

  &__img {
    position: absolute;
    width: 30%;
    animation-name: vertical;

    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    &--go {
      top: 40%;
      left: 0;
      animation-duration: 1s;
    }

    &--react {
      top: 20%;
      left: 7%;
      animation-duration: 1.2s;
    }

    &--php {
      top: 59%;
      left: 9%;
      animation-duration: 1.6s;
    }

    &--salesforce {
      top: 20%;
      left: 26%;
      animation-duration: 1.4s;
    }

    &--rails {
      top: 20%;
      left: 47%;
      animation-duration: 1.3s;
    }

    &--docker {
      top: 40%;
      left: 42%;
      animation-duration: 1.7s;
    }

    &--vuejs {
      top: 5%;
      left: 60%;
      animation-duration: 1.5s;
    }

    &--swift {
      top: 0;
      left: 42%;
      animation-duration: 1.2s;
    }

    &--java {
      top: 0;
      left: 22%;
      animation-duration: 1.6s;
    }

    &--heroku {
      top: 33%;
      left: 65%;
      animation-duration: 1.5s;
    }

    &--aws {
      top: 45%;
      left: 21%;
      animation-duration: 1.4s;
    }

    &--flutter {
      top: 56%;
      left: 57%;
      animation-duration: 1.8s;
    }

    &--htmlcss {
      top: 65%;
      left: 35%;
      animation-duration: 1.7s;
    }
  }
}

@keyframes vertical {
  0% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
  }
}