.salesforce {
  &__inner {
    max-width: 886px;
    margin: auto;
    padding: 0 25px;
  }

  &__sp-only {
    @include min-M {
      display: none;
    }
  }

}