.about-certifications {
  padding: 80px 25px 120px;
  background: $tint-blue;
  @include min-M {
    padding: 120px 25px 200px;
  }

  &__title {
    margin-bottom: 48px;
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  &__read {
    margin: auto;
    margin-bottom: 80px;
    max-width: 768px;
  }
  &__box {
    margin: auto;
    max-width: 980px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .06);
    &:last-child {
      margin-top: 40px;
    }
  }
  &__heading {
    padding: 10px 20px;
    background: rgba(239, 241, 247, .50);
    color: $light-navy;
    letter-spacing: .0375em;
    font-weight: 700;
    line-height: 1.6;
  }
  &__group {
    margin: 0 20px;
    padding: 24px 10px;
    border-bottom: 2px solid $tint-blue;
  }
  &__group-title {
    margin-bottom: 8px;
    letter-spacing: .0325em;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.6;
  }
  &__list {
    list-style: disc;

    padding-inline-start: 20px;
  }
  &__list-item {
    letter-spacing: .035em;
    font-size: 14px;
    line-height: 1.6;
  }
}
