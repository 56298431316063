.footer-contact {
  padding: 0 $container-padding;
  background-image: url("/assets/img/bg-footer-contact.jpg");
  background-position: 100% 55%;
  background-size: cover;
  text-align: center;
  &__inner {
    padding: 50px 0;

    @include min-M {
      padding: 100px 0;
    }
  }
  &__text {
    margin-bottom: 30px;
    color: #fff;

    @include min-M {
      font-size: 2rem;
    }
  }
}
