.cloud-native-advantage {
  @include min-M {
  }
  &__inner {
    padding: 80px 25px 80px;
    @include min-M {
      padding: 80px 25px 120px;
    }
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 26px;
    }
  }
  &__wrap {
    margin: auto;
    padding: 64px 0;
    max-width: 836px;
    width: 100%;
  }
  &__heading {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    @include min-M {
      padding-bottom: 24px;
      font-size: 24px;
    }
    &--padding-top {
      padding-top: 50px;
      @include min-M {
        padding-top: 40px;
      }
    }
  }
  &__row {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    @include min-M {
      align-items: flex-start;
      flex-direction: row;
      gap: 40px;
    }
  }
  &__img {
    width: 100%;
    @include min-M {
      width: 300px;
    }
  }
  &__box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    padding: 25px 30px;
    max-width: 836px;
    width: 100%;
    background: #f6f6e9;
    @include min-M {
      padding: 40px 56px;
    }
    @include min-L {
      flex-direction: row;
    }
    &--margin-top {
      margin-top: 16px;
    }
  }
  &__read {
    padding-bottom: 20px;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 1.7;
    @include min-L {
      padding-bottom: 0;
      padding-bottom: 30px;
    }
  }
  &__card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    @include min-M {
      flex-wrap: nowrap;
    }
    @include min-L {
      max-width: 560px;
    }
  }
  &__card {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 8px 24px;
    width: calc(50% - 12px);
    border-radius: 8px;
    background: #fff;
    @include min-M {
      width: 100%;
    }
  }
  &__logo-img {
    max-width: 80px;
    width: 100%;
  }
  &__caption {
    color: $navy;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
  }
  &__group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    @include min-L {
      max-width: 560px;
    }
  }
  &__label {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
    &--padding-top {
      padding-top: 24px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 12px;
  }
  &__list-item {
    padding: 4px 16px;
    border-radius: 36px;
    background: rgba(255, 255, 255, .75);
    color: $navy;
    white-space: nowrap;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.5;
  }
}
