$card-width: 412px !default;

%case-card,
.case-card {
  display: block;
  padding: 25px 12px 50px;
  /* white */
  color: #fff;
  /* ボックスの高さと同一、または114% */
  text-align: center;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;

  &--works {
    padding: 32px 0;
    @include min-M {
      padding: 32px;
    }
    @include min-L {
      padding: 48px;
    }
  }

  &__img {
    width: 100%;
  }

  &__title {
    margin: 8px 0;
    color: $light-black;
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    @include min-S {
      font-size: 19px;
    }
    &--large {
      @include min-M {
        font-size: 21px;
      }
    }
  }

  &__technology-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__technology-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 24px;
    border: 1px solid $light-black;
    border-radius: 24px;
    color: $light-black;
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }
}
