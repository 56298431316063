.workflow-firstview {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url(/assets/img/workflow/top-bg.png);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  @include min-M {
    background-image: url(/assets/img/workflow/top-bg@2x.png);
  }
  &__inner {
    padding-top: 100px;
    @include min-M {
      padding-top: 115px;
    }
  }

  &__text {
    padding-top: 50px;
    padding-bottom: 80px;
    text-align: center;
    @include min-M {
      font-size: 1.6rem;
    }
  }
}
