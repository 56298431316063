.case-others {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 80px;
  width: 100%;
  border-top: 1px solid $tint-blue;
  text-align: center;
  @include min-M {
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 100px;
  }
  &__heading {
    margin-bottom: 50px;
    font-weight: bold;
    font-size: 1.8rem;
    @include min-M {
      font-size: 2rem;
    }
  }
  &__button-wrap {
    margin-top: 40px;
  }
  &__row {
    margin: 0 -20px;
    @include min-M {
      margin: 0;
    }
  }
  &__slider {
    margin: 0 auto;
    max-width: 1236px;
  }
}
