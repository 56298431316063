$footer-height: 138px !default;

.main {
  display: block; //IE対応
  min-height: calc(100vh - #{$footer-height});

  &--padding {
    padding-top: 100px;
    @include min-L {
      padding-top: 115px;
    }
  }
}
