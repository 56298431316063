.ui-design-firstview {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  background-image: url("/assets/img/service/ui-design/top-bg@2x.jpg");
  background-size: 62%;
  background-repeat: no-repeat;
  @include min-M {
    background-size: 45%;
  }
  &__inner {
    padding: 0 25px;
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 2.6rem;
    @include min-M {
      margin-top: 80px;
      margin-bottom: 66px;
      font-size: 36px;
      line-height: 1;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
    @include min-M {
      gap: 48px;
      padding-top: 80px;
    }
  }
  &__lead {
    color: $light-navy;
    text-align: center;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    @include min-M {
      max-width: 836px;
      width: 100%;
      text-align: left;
      font-size: 26px;
      line-height: 1;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    @include min-M {
      align-items: flex-start;
      flex-direction: row;
      gap: 40px;
      max-width: 835px;
    }
  }
  &__first-text-group {
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
  }
  &__second-picture {
    flex-shrink: 0;
  }
  &__list-text {
    align-self: stretch;
    color: $light-black;
    text-align: left;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    @include min-M {
      font-size: 15px;
      line-height: 1.9;
    }
  }
  &__text-blank {
    display: block;
    margin-top: 10px;
    content: "";
  }
  &__second-text-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    order: 1;
    gap: 25px;
    @include min-M {
      order: 2;
    }
  }
  &__second-picture {
    order: 2;
    @include min-M {
      order: 1;
    }
  }
  &__img {
    width: 100%;
  }
  &__first-img {
    max-width: 330px;
  }
  &__second-img {
    max-width: 336px;
  }
}
