.icon-link {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  font-weight: bold;
  font-size: 1.5rem;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 26px;
    background-image: url("/assets/img/module/icon-circle-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    content: "";
  }
}
