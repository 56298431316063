.case-summary {
  $this: &;
  padding-top: 50px;
  padding-bottom: 50px;
  @include min-M {
    margin-bottom: 80px;
    padding-top: 60px;
    padding-bottom: 20px;
    border-bottom: 5px solid $border-point;
  }

  &--under-img {
    margin-bottom: 35px
  }

  &__heading {
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
  }
  &__text {
    font-size: 15px;

    & + #{$this}__heading {
      margin-top: 32px;
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 20px;
  }
  &__label {
    padding: 8px 16px;
    border-radius: 5px;
    background: #eff1f7;
    color: $navy;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  &__scope-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    font-size: 14px;
    margin-bottom: 12px;
  }
}
