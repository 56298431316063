.top-first-view {
  position: relative;
  min-height: 650px;
  width: 100%;
  height: 100vh;
  color: #fff;
  &::after {
    // 人々の背景画像
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100vw;
    height: 100vh;
    background-image: url("/assets/img/index/bg-fv.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    content: "";
  }

  &::before {
    //背景ロゴ
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(#0f1e7c, .5);
    background-image: url("/assets/img/index/bg-fv-logo.svg");
    background-position: right -72% top -50px;
    background-size: 82%;
    background-repeat: no-repeat;
    content: "";

    @include min-M {
      background-position: right -15px top -60px;
      background-size: 57%;
    }
  }
  &__heading{
    position: absolute;
    top: -100%;
    opacity: 0;
  }

  &__container {
    position: absolute;
    top: 230px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    @include min-S {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
    }
    @include min-L {
      padding: 90px 0 0 60px;
    }
  }

  &__catch-copy-wrap {
    margin-bottom: 4vh;
    text-align: center;

    @include min-S {
      margin-top: 0;
      margin-bottom: 60px;
      text-align: left;
    }
  }

  &__catch-copy {
    max-width: 290px;
    width: 100%;

    @include min-S {
      max-width: 407px;
    }
  }

  &__lead {
    display: none;

    @include min-S {
      display: block;
      margin-bottom: .25em;
      text-align: left;
      letter-spacing: .05em;
      font-weight: bold;
      font-size: 1.8rem;
    }
  }

  &__text {
    margin: auto auto 5vh auto;
    letter-spacing: .05em;
    font-size: 1.4rem;
    width: 310px;
    max-width: 100%;
    @include min-S {
      margin-bottom: 50px;
      width: 100%;
    }
  }

  &__button-area {
    text-align: center;

    @include min-S {
      text-align: left;
    }
  }
  &__button {
    border-color: #fff;
    color: #fff;
    font-size: 1.8rem;
    &:hover {
      background-color: #fff;
      color: $color-point;
    }
  }

  &__scroll-button {
    display: none;

    @include min-S {
      position: absolute;
      bottom: 3rem;
      bottom: 38px;
      left: 50%;
      z-index: 10;
      display: block;
      padding-top: 40px;
      color: rgba(255, 255, 255, .5);
      text-decoration: none;
      font-size: 120%;
      font-family: $font-josefin;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);

      &::before {
        display: none;

        @include min-S {
          position: absolute;
          top: 0;
          left: 50%;
          display: block;
          margin-left: -12px;
          width: 24px;
          height: 24px;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          content: " ";
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
          -webkit-animation: scroll-down 2s infinite;
                  animation: scroll-down 2s infinite;

          @-webkit-keyframes scroll-down {
            0% {
              -webkit-transform: rotate(-45deg) translate(0, 0);
            }

            20% {
              -webkit-transform: rotate(-45deg) translate(-10px, 10px);
            }

            40% {
              -webkit-transform: rotate(-45deg) translate(0, 0);
            }
          }

          @keyframes scroll-down {
            0% {
              transform: rotate(-45deg) translate(0, 0);
            }

            20% {
              transform: rotate(-45deg) translate(-10px, 10px);
            }

            40% {
              transform: rotate(-45deg) translate(0, 0);
            }
          }
        }
      }
    }
  }
}
