.case-notes{
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 1px solid $tint-blue;
  @include min-L{
    padding-bottom: 64px;
    margin-bottom: 80px;
  }
  &__text{
    font-size: 15px;
    &+&{
      margin-top: 27px;
    }
  }
  &__stress{
    font-weight: bold;
    mix-blend-mode: multiply;
    position: relative;
    padding: 0 0.5em;
    &:before{
      position: absolute;
      bottom: -.2em;
      left:.25em;
      content: "";
      display: inline-block;
      width: calc(100% - 0.5em);
      height: 9px;
      background: rgba($color-case-4,.3);
      z-index: -1;
    }
  }
}