.top-second-view {
  &__container {
    z-index: 4;
  }

  &__heading {
    padding-bottom: 1.5em;
    color: #fff;
    text-align: center;
    font-weight: bold;
    font-size: 2.6rem;

    @include min-S {
      letter-spacing: .05em;
      font-size: 3.5rem;
    }
  }

  &__inner {
    padding: 80px 0;

    @include min-M {
      padding: 100px 0 120px;
    }
  }

  &__row {
    margin-bottom: 40px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    @include min-S {
      margin-bottom: 0;
    }
  }

  &__col {
    padding: 1px 0;
    position: relative;

    @include min-S {
      padding: 14px 15px 6px;
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include min-L {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &__card {
    padding: 10px 15px;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(#fff, .8);
    color: $color-point;

    mix-blend-mode: overlay;
    @include min-S {
      padding: 26px 26px 20px;
    }
  }

  &__card-heading {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.6;

    @include min-S {
      margin-bottom: 12px;
      text-align: center;
      font-size: 1.7rem;
      line-height: 1.3;
    }
  }

  &__card-heading-inner{
    &:not(:first-child){
      margin-left: -.25em;
    }
  }

  &__card-content {
    display: none;

    @include min-S {
      display: block;
      font-size: 1.4rem;
      line-height: 1.4;
    }
  }

  &__banner-area {
    text-align: center;
    margin-bottom: 26px;
  }

  &__banner-link {
    &:hover {
      opacity: 1;
    }
  }

  &__banner-img {
    width: 100%;
    max-width: 680px;
    box-shadow: 10px 10px 50px -20px rgba(0, 0, 0, 0.50);

    &:hover {
      box-shadow: none;
    }
  }
}
