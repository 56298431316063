.performance-list {
  margin-top: 120px;
  padding-top: 40px;
  padding-bottom: 65px;
  background-color: $color-box;
  @include min-M {
    margin-top: 120px;
    padding-top: 118px;
    padding-bottom: 200px;
  }
  &__heading {
    margin-bottom: 48px;
    color: $color-heading;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    @include min-M {
      font-size: 2.6rem;
    }
  }
  &__container {
    overflow-x: auto;
    margin: 0 auto;
    padding: 0 25px;
    max-width: calc(1052px + 25px * 2);
    width: 100%;
    /*スクロールバー非表示（IE・Edge）*/

    -ms-overflow-style: none;
    /*スクロールバー非表示（Firefox）*/
    scrollbar-width: none;

    /*スクロールバー非表示（Chrome・Safari）*/
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__table {
    width: 900px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .06);
    word-break: break-all;
    @include min-M {
      width: 100%;
    }
  }
  &__title {
    background: rgba(239, 241, 247, .5);
  }
  &__title-text {
    padding: 10px;
    color: $navy;
    letter-spacing: .04em;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    &--category {
      @include min-M {
        padding-left: 20px;
      }
    }
    &--title {
      width: 20%;
    }
    &--technology {
      width: 0;
      white-space: nowrap;
    }
  }
  &__item {
    border-top: 2px solid $tint-blue;
  }
  &__item-text {
    padding: 10px;
    color: $light-black;
    letter-spacing: .04em;
    font-weight: 500;
    font-size: 13px;
    line-height: 160%;

    &--category {
      width: 0;
      vertical-align: middle;
      white-space: nowrap;
      @include min-M {
        padding-left: 20px;
      }
    }
    &--title {
      vertical-align: middle;
      &--technology {
        @include min-M {
          padding-right: 20px;
        }
      }
    }
    &--technology {
      word-break: break-word;
    }
  }
  &__category {
    &::before {
      display: inline-block;
    }
    &::after {
      display: inline-block;
      margin-left: 5px;
      vertical-align: bottom;
      line-height: 30px;
    }
    &--finance {
      &::before {
        content: url(/assets/img/works/icon-finance.svg);
      }
      &::after {
        content: "金融";
      }
    }
    &--car {
      &::before {
        content: url(/assets/img/works/icon-car.svg);
      }
      &::after {
        content: "自動車";
      }
    }
    &--book {
      &::before {
        content: url(/assets/img/works/icon-book.svg);
      }
      &::after {
        content: "出版";
      }
    }
    &--service {
      &::before {
        content: url(/assets/img/works/icon-service.svg);
      }
      &::after {
        content: "サービス";
      }
    }
    &--infrastructure {
      &::before {
        content: url(/assets/img/works/icon-infrastructure.svg);
      }
      &::after {
        content: "インフラ";
      }
    }
  }
}
