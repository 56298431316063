.accordion {
  &__header {
    cursor: pointer;
  }
  &__body {
    overflow: hidden;
    max-height: 0;
    transition: max-height .3s ease, padding .3s ease;
  }
  &__body.show {
    max-height: inherit;
  }
  &__header:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }
}
