.ui-design-onestop {
  background-color: $bg-blue;
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding: 80px 0;

    gap: 48px;
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;

    @include min-M {
      font-size: 26px;
      line-height: 1;
    }
  }
  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 25px;
    padding: 25px 30px;
    border-radius: 8px;
    background-color: #fff;

    gap: 20px;
    @include min-M {
      padding: 32px 36px;
    }
    @include min-L {
      padding: 64px 72px;
    }
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: none;
    width: 100%;

    gap: 32px;
    @include min-L {
      align-items: flex-end;
      flex-direction: row;
      max-width: 836px;

      gap: 64px;
    }
  }
  &__list-item--top {
    align-items: center;

    @include min-L {
      align-items: flex-start;
    }
  }
  &__text-group {
    flex: 1 0 0;
    max-width: initial;

    @include min-L {
      max-width: 420px;
    }
  }
  &__text-group--small {
    max-width: none;

    @include min-L {
      max-width: 420px;
    }
  }
  &__area-read {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    margin-bottom: 24px;
    max-width: 420px;
    width: 100%;

    gap: 8px;
    @include min-M {
      margin-bottom: 48px;
    }
  }
  &__read-img {
    max-width: 50px;
    width: 100%;

    @include min-M {
      max-width: 100px;
    }
  }
  &__read-text {
    flex: 1 0 0;
    color: $light-navy;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;

    @include min-M {
      font-size: 24px;
      line-height: 1.9;
    }
  }
  &__read-text--small {
    padding-bottom: 16px;
    font-size: 15px;

    @include min-M {
      padding-left: 24px;
      font-size: 18px;
      line-height: 1.5;
    }
  }
  &__text {
    color: #000;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    line-height: 1.9;

    @include min-M {
      padding-left: 24px;
    }
    &:not(:first-of-type) {
      margin-top: 26px;
    }
  }
  &__area-arrow {
    position: relative;
    display: block;
    height: 44px;
  }
  &__line {
    position: absolute;
    margin-top: 11px;
    width: 100%;
    height: 2px;
    border: none;
    background-color: $bg-blue;
  }
  &__arrow-img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  &__img {
    width: 100%;
  }
  &__first-img--01 {
    max-width: 311px;
  }
  &__first-img--02 {
    max-width: 309px;
  }
  &__img-space {
    @include min-L {
      width: 352px;
    }
  }
  &__second-img {
    max-width: 318px;
  }
}
