.label {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  &__item {
    display: inline-block;
    margin-top: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    background: $light-black;
    color: #fff;
    text-align: center;
    font-weight: 700;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
  }
}
