@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  @if $top != null {
    top: $top;
  }
  @if $right != null {
    right: $right;
  }
  @if $bottom != null {
    bottom: $bottom;
  }
  @if $left != null {
    left: $left;
  }
}
// @include absolute($top:0,$right:0);
// 指定しなければauto
