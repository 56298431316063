.case-link {
  &__img {
    display: block;
    margin: auto;
    max-width: 788px;
    width: 100%;
  }

  &__text {
    margin-top: 41px;
    color: $light-black;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-decoration-line: underline;
  }
}
