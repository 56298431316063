//開発事例ページ
.case-firstview {
  padding-top: 73px;
  background-position: right 150% bottom 0;
  background-size: auto 70%;
  background-repeat: no-repeat;
  @include min-M {
    padding-top: 124px;
    height: 473px;
    background-position: right 10% bottom 0;
    background-size: auto;
  }

  &--bank-backend {
    background-color: $color-case-bank-backend;
    background-image: url("/assets/img/case/bank-backend.jpg");
  }

  &--residents-dx {
    background-color: $color-case-residents-dx;
    background-image: url("/assets/img/case/residents-dx.jpg");
  }

  &--office-search {
    background-color: $color-case-office-search;
    background-image: url("/assets/img/case/office-search.jpg");
  }

  &--stay-dx {
    background-color: $color-case-stay-dx;
    background-image: url("/assets/img/case/stay-dx.jpg");
  }

  &--estimate-cloud {
    background-color: $color-case-estimate-cloud;
    background-image: url("/assets/img/case/estimate-cloud.png");
  }

  &--customer-cloud {
    background-color: $color-case-customer-cloud;
    background-image: url("/assets/img/case/customer-cloud.jpg");
  }

  &--employment-site {
    background-color: $color-case-employment-site;
    background-image: url("/assets/img/case/employment-site.jpg");
  }

  &--corporate-site {
    background-color: $color-case-corporate-site;
    background-image: url("/assets/img/case/corporate-site.jpg");
  }

  &--parking-cashless {
    background-color: $color-case-parking-cashless;
    background-image: url("/assets/img/case/parking-cashless.jpg");
  }

  &--finance-form {
    background-color: $color-case-finance-form;
    background-image: url("/assets/img/case/finance-form.jpg");
  }

  &--withtalk-web {
    background-color: $color-case-withtalk-web;
    background-image: url("/assets/img/case/withtalk-web.jpg");
  }

  &--tumugu-site {
    background-color: $color-case-tumugu-site;
    background-image: url("/assets/img/case/tumugu-site.jpg");
  }

  &--newspaper-renewal {
    background-color: $color-case-newspaper-renewal;
    background-image: url("/assets/img/case/newspaper-renewal.jpg");
  }

  &__label {
    font-weight: bold;
  }

  &__heading {
    margin: 20px 0 25px;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.3;
  }

  &__sub-heading {
    font-size: 18px;
  }

  &__outline {
    padding: 45px 0 100px;
    min-height: 300px;
    @include min-M {
      padding-top: 30px;
      padding-bottom: 105px;
    }
  }

  &__list {
    font-size: 16px;
    line-height: 1.8;
  }

  &__list-heading {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    &:after {
      margin-right: 3px;
      margin-left: 6px;
      content: ":";
      font-weight: bold;
    }
  }

  &__list-data {
    display: inline-block;
  }
  &__list-data--link {
    display: block;
    margin: -.3em 0 .5em 0;
  }

  &__language {
    display: block;
    padding-top: 10px;
    border-top: 1px dotted $border-point;
    font-size: 0;
  }

  &__language-item {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.5;
    &:not(:last-child) {
      &:after {
        margin-right: .3em;
        margin-left: .3em;
        content: "/";
      }
    }
  }
}
