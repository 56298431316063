.heading {
  color: $color-heading;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: bold;
  font-size: 2.6rem;
  font-family: $font-josefin;
  line-height: 1;

  @include min-M {
    font-size: 3rem;
  }
}
