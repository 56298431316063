/**
 * Grid system
 *
 * クラス名は違うがbootstrap4のグリッドシステムを使用
 * シンプルなグリッドのときのみ使用する
 */

// 変数
// col同士の幅
$padding: 40px !default;
// 分割数
$grid: 12 !default;

@mixin row {
  @include flex();
  margin: 0 calc(-1 * $padding / 2);

  &--center {
    @include flex($justify: center);
  }

  &--right {
    @include flex($justify: flex-end);
  }

  &--nowrap {
    @include flex($wrap: nowrap);
  }
}

@mixin col {
  position: relative;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: calc($padding / 2);
  max-width: 100%;
  // row__col
  // 親の幅めいっぱいの成り行きのサイズ
  // 複数並べた場合は等分されたサイズ
  // https://getbootstrap.jp/docs/4.3/layout/grid/#equal-width


  &--auto {
    position: relative;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    padding: calc($padding / 2);
    max-width: 100%;
    width: inherit;




    // row__col--auto
    // コンテンツの自然な幅または、子要素の幅に基づいたサイズ
    // https://getbootstrap.jp/docs/4.3/layout/grid/#variable-width-content
  }

  @for $i from 1 through $grid {
    &--#{$i} {
      position: relative;
      -ms-flex: 0 0 calc(100% / $grid) * $i;
          flex: 0 0 calc(100% / $grid) * $i;
      padding: calc($padding / 2);
      max-width: calc(100% / $grid) * $i;
      // row__col--{等分の数}
      // グリッドのサイズ
    }
  }
}


// [すべてのウインドウサイズ]
.row {
  @include row;
}

.col {
  @include col;
}

@include min-S {
  // [481px以上]
  %row-s,
  .row-s {
    @include row;
  }

  %col-s,
  .col-s {
    @include col;
  }
}

@include min-M {
  // [769px以上]
  %row-m,
  .row-m {
    @include row;
  }

  %col-m,
  .col-m {
    @include col;
  }
}

@include min-L {
  // [1024px以上]
  %row-l,
  .row-l {
    @include row;
  }

  %col-l,
  .col-l {
    @include col;
  }
}

.col--card {
  padding: 7px 30px 0 !important;
  @include min-S {
    padding: 7px 20px 0 !important;
  }
}

.col--card-case {
  & + & {
    margin-top: 40px !important;
    padding-top: 0 !important;
    @include min-M {
      margin-top: 0 !important;
      padding-top: 7px !important;
    }
  }
}
