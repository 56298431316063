.breadcrumb {
  font-size: 1.3rem;
  letter-spacing: 0.05em;
  font-family: $font-josefin;
  &__item {
    display: inline;
    font-weight: bold;
    &:not(:last-child) {
      &:after {
        margin-left: 5px;
        content: ">";
      }
    }
  }
}
