.contact-container {
  margin: 0 auto;
  padding: 0 30px;
  max-width: 660px;//600px+30px*2

  @include min-L {
    max-width: 740px;
  }
  &--small {
    max-width: 500px;
  }
}
