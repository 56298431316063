.appear-device {
  z-index: -1;
  width: 100%;
  height: 1000px;

  @include absolute($left: 0, $top: 0);

  &__item {
    position: absolute;

    &--tablet {
      top: 50px;
      left: -250px;
    }

    &--notepc {
      top: 725px;
      left: -250px;
    }

    &--pc {
      top: 200px;
      right: -250px;
    }

    &--phone {
      top: 600px;
      right: -250px;
    }
  }


  .deviceFadeIn-on.appear-device__item--left {
    perspective: 1000; //正の値
    animation: fadein-left 1s ease-in-out forwards normal;
  }

  .deviceFadeIn-on.appear-device__item--right {
    perspective: 1000; //正の値
    animation: fadein-right 1s ease-in-out forwards normal;
  }
}

@keyframes fadein-right {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: translate(-250px, 0);
  }
}

@keyframes fadein-left {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: translate(250px, 0);
  }
}