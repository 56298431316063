.service-others {
  padding-top: 80px;
  padding-bottom: 130px;

  @include min-M {
    padding-top: 0;
  }

  &__inner {
    max-width: 980px;
    margin: auto;
    @include min-M {
      padding-top: 74px;
    }
  }

  &__title {
    color: $navy;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 68px auto 0;
    padding: 0 20px;
    flex-direction: column;
    row-gap: 47px;
    max-width: 600px;

    @include min-S {
      margin-top: 90px;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 0 76px;
    }

    @include min-M {
      margin-top: 87px;
      flex-wrap: nowrap;
      max-width: initial;
      padding: 0 35px;
    }

    &::before {
      content: url(/assets/img/service/cross.svg);
      display: block;
      width: 20px;
      position: absolute;
      top: -40px;
      left: 20px;

      @include min-S {
        width: 15px;
        top: -33px;
        left: 52px;
      }

      @include min-M {
        content: none;
      }
    }

    &::after {
      content: url(/assets/img/service/cross.svg);
      display: block;
      width: 20px;
      position: absolute;
      top: -40px;
      right: 20px;

      @include min-S {
        width: 15px;
        top: -33px;
        right: 52px;
      }

      @include min-M {
        content: none;
      }
    }
  }

  &__item {
    position: relative;
    text-align: center;
    flex-basis: 20%;

    &::before {
      content: url(/assets/img/service/cross.svg);
      position: absolute;
      display: block;
      width: 20px;
      bottom: -56px;
      left: 0;

      @include min-S {
        width: 15px;
        left: -21px;
        bottom: -53px;
      }

      @include min-M {
        content: none;
      }
    }

    &::after {
      content: url(/assets/img/service/cross.svg);
      position: absolute;
      display: block;
      width: 20px;
      bottom: -56px;
      right: 0;

      @include min-S {
        width: 15px;
        right: -21px;
        bottom: -53px;
      }

      @include min-M {
        top: -34px;
        right: -41px;
      }
    }

    &:nth-child(4) {
      &::before {
        content: url(/assets/img/service/cross.svg);
      }
      &::after {
        content: url(/assets/img/service/cross.svg);

        @include min-M {
          content: none;
        }
      }
    }

    @include min-S {
      &:nth-child(even)::before {
        content: none;
      }

      &:nth-child(odd)::after {
        content: none;
      }
    }

    @include min-M {
      &:nth-child(odd)::after {
        content: url(/assets/img/service/cross.svg);
      }
    }

    @include min-S {
      flex-basis: 45%;
    }

    @include min-M {
      flex-basis: 20%;
    }
  }

  &__content {
    width: 225px;
    margin: auto;

    @include min-S {
      width: auto;
    }

    @include min-M {
      flex-basis: auto;
    }
  }

  &__content-title {
    margin-top: 20px;
    color: $light-navy;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.1em;

    @include min-S {
      margin-top: 29px;
    }
  }

  &__content-text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.67;
    text-align: left;

    @include min-S {
      margin-top: 18px;
    }
  }
}