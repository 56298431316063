//SEのみ表示
.se-only {
  @include max-SE {
    display: block;
  }
}

//481px以下表示
.sp-S-only {
  @include min-S {
    display: none;
  }
}

//SP以下表示
.sp-only {
  @include min-M {
    display: none;
  }
}

//SP以上表示
.se-only-none {
  @include max-SE {
    display: none;
  }
}

//TB以上表示
.tb-pc-only {
  display: none;
  @include min-S {
    display: block;
  }
}

//PC以上表示
.pc-only {
  display: none;
  @include min-M {
    display: block;
  }
}

//1024px以上表示
.pc-L-only {
  display: none;
  @include min-L {
    display: block;
  }
}

// 左・右・中央揃え
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

//ボタンのテキストが2文字の場合に使用
.button-letter-spacing {
  text-indent: 1em;
  letter-spacing: 1em;
}
