.member-firstview {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-image: url(/assets/img/member/member_pattern-sp.png);
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  @include min-M {
    background-image: url(/assets/img/member/member_pattern-pc.png);
  }
  &__inner {
    padding-top: 100px;
    @include min-M {
      padding-top: 115px;
    }
  }
  &__text {
    margin-top: 50px;
    padding: 0 25px;
    text-align: left;
    font-size: 15px;
    @include min-M {
      padding: 0;
      text-align: center;
    }
  }
  &__img {
    margin-top: 30px;
    width: 100%;
  }
}
