.top-works {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-bottom: 70px;
  background-color: #fff;

  @include min-M {
    padding-bottom: 100px;
  }
  &__box {
    margin: 0 auto 50px;
    padding: 40px $container-padding 70px;
    max-width: 780px;
    background-color: $color-box !important;

    @include min-M {
      padding-right: 50px;
      padding-bottom: 60px;
      padding-left: 50px;
      border-radius: 10px;
    }
    @include min-M {
      border-radius: 10px;
      background-color: #fff;
    }
  }
  &__box-inner {
    margin: 0 auto;
    max-width: 600px;

    @include min-L {
      max-width: inherit;
    }
  }
  &__heading {
    margin-bottom: 20px;
    color: $color-heading;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
  }
  &__item-heading {
    margin-bottom: 5px;
    color: $color-heading;
    font-weight: bold;
    font-size: 1.8rem;

    @include min-M {
      font-size: 2rem;
    }
  }
  &__item {
    &+& {
      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid #fff;
    }
  }
  &__text {
    margin: 15px 0 20px;
  }
}
