.navi {
  &__overlay {
    transition: all .3s;
  }
  &__main {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 990;
    width: 100%;
    height: 100%;
    background-color: $color-base;
    opacity: 0;
    transition: all .3s;
    pointer-events: none;
    @include min-S {
      right: -320px;
      width: 320px;
    }
  }

  &__main-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__list {
    overflow: auto;
    padding: 25px 0;
    width: 100%;
    text-align: left;
  }

  &__list-item {
    position: relative;
    margin: auto;
    width: 250px;
    line-height: 1.1;
    &:not(:first-child) {
      margin-top: 38px;
    }
    &--child {
      line-height: 1;
      &:first-child::before {
        content: "";
      }
      &:not(:first-child) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }

  &__list-link {
    display: inline-block;
    color: #fff;
    letter-spacing: .13em;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.1;

    &:hover {
      color: rgba(#fff, .65);
      opacity: inherit;
      transition: color .2s ease;
    }
    &--icon {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      img {
        transition: all .3s ease;
      }
      &:hover img {
        opacity: .7;
      }
    }
    &--button {
      display: inline-block;
      padding: 0 40px;
      max-width: 100%;
      width: 250px;
      height: 53px;
      border: solid 1px #fff;
      color: #fff;
      text-align: center;
      letter-spacing: .1em;
      font-weight: 600;
      font-size: 1.8rem;
      font-family: "Josefin Slab";
      line-height: 53px;
      transition: border .2s ease;

      &:hover {
        border: solid 1px rgba(#fff, .65);
      }
    }
    &--child {
      margin-left: 20px;
      letter-spacing: .04em;
      font-weight: 500;
      font-size: 13px;
      font-family: $base-font-family;
      line-height: 1;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    margin-top: -3px;
    margin-left: 20px;
    width: 24px;
    height: 24px;
    pointer-events: auto;
  }

  &__icon-inner {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: $color-point;
    transform: translateY(-50%);

    &:before,
    &:after {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: $color-point;
      content: "";
      transition: rotate .3s linear;
    }

    &:before {
      top: -7px;
    }

    &:after {
      top: 7px;
    }
  }
}

//メニュー開いたとき
.is-menu-open .navi__overlay {
  @include min-S {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100vh;
    background: rgb(0 0 0 / 50%);
  }
}
.is-menu-open .navi__main {
  opacity: .9;
  pointer-events: auto;
  @include min-S {
    right: 0;
  }
}

.is-menu-open .navi__icon {
  z-index: 999;
}

.is-menu-open .navi__icon-inner {
  background-color: transparent !important;

  &:before,
  &:after {
    top: 0;
    background-color: #fff;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

//ファーストビューのみアイコンを白に
.top .is-white {
  .navi__icon-inner,
  .navi__icon-inner:before,
  .navi__icon-inner:after {
    background-color: #fff;
  }
}

//メニューを開いたときうしろを止める
.is-menu-open {
  overflow: hidden;
  @include min-L {
    touch-action: inherit;
  }

  touch-action: none;
}
