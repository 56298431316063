.contact-confirm {
	&__heading {
		margin-top: 50px;
		margin-bottom: 30px;
		text-align: center;
		font-weight: bold;
		font-size: 1.8rem;

		@include min-L {
			margin-top: 70px;
			margin-bottom: 50px;
		}
	}
	&__list {
		padding: 15px 0;
		border-bottom: 1px solid #000;

		@include min-L {
			display: flex;
			padding: 24px 0;
		}
	}
	&__list-heading {
		display: flex;
		align-items: center;
		color: $color-form;
		font-weight: 300;
		font-size: 1.4rem;

		@include min-L {
			min-width: 170px;
			width: 170px;
		}
	}
	&__list-detail {
		color: $color-form-text;
		font-size: 1.6rem;
		&--textarea {
			@include min-L {
				min-height: 170px;
			}
		}
	}
}
