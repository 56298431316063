.about-technology {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px 25px 120px;
  background: $tint-blue;
  @include min-M {
    padding: 120px 0;
  }

  &__title {
    margin-bottom: 48px;
    color: $navy;
    font-weight: 700;
    font-size: 26px;
    line-height: 1.5;
  }
  &__read {
    margin-bottom: 56px;
    max-width: 980px;
    @include min-M {
      padding: 0 25px;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 25px;
    max-width: none;
    width: calc(100% - 50px);
    border-radius: 8px;
    background-color: #fff;
    @include min-L {
      max-width: 980px;
      width: 100%;
    }
  }
  &__box {
    margin: 0 25px;
    padding: 40px 0;
    max-width: none;
    width: calc(100% - 50px);
    border-bottom: 1px solid $light-blue-num;
    @include min-L {
      margin: 0 40px;
      max-width: 868px;
      width: 100%;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &__category {
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.7;
  }
  &__list {
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
    width: 112px;
  }
  &__img {
    width: 80px;
    height: 80px;
  }
  &__list-text {
    color: $navy;
    font-weight: 700;
    font-size: 16px;
  }
}
