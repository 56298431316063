.cloud-native-quality {
  padding: 80px 0;
  background-color: $tint-blue;

  &__inner {
    padding: 0 25px;
  }
  &__title {
    padding-bottom: 24px;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.5;
    @include min-M {
      font-size: 26px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin: 16px auto 0;
    padding: 25px 30px;
    max-width: 980px;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    @include min-M {
      padding: 48px 72px;
    }
    @include min-L {
      flex-direction: row;
    }
  }

  &__item {
    &--order1 {
      order: 2;
      @include min-L {
        order: 1;
      }
    }
    &--order2 {
      order: 1;
      @include min-L {
        order: 2;
      }
    }
  }
  &__group {
    display: flex;
    align-items: center;
    @include min-S {
      align-items: flex-end;
    }
  }

  &__read-text {
    padding-left: 5px;
    color: $navy;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5;
    @include min-S {
      font-size: 24px;
      line-height: 1;
    }
  }
  &__text {
    padding-top: 28px;
    @include min-M {
      padding-left: 8px;
    }
  }
  &__img {
    width: 100%;
    @include min-M {
      width: 349px;
    }
  }
}
